import React, { useEffect, useState } from "react";
import { useAppState } from "../../contexts/formContext";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";
import "./step4.css";

function Step4({
    step4ValidationErrors,
    invalidateStep4Errors,
}) {
    const [state, setState] = useAppState();
    const [partner1ShowOptions, setshowPartner1Options] = useState();
    const [showPartner2Options, setshowPartner2Options] = useState(false);
    const [t] = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSelectFortuneSelection = (value) => {
        setState((state) => {
            if (
                state?.["Sélections des fortune"] &&
                state?.["Sélections des fortune"][value]
            ) {
                if (value === "Comptes Bancaires") {
                    invalidateStep4Errors("combien comptes bancaires");
                    const { [value]: fort, ...rest } = state?.["Sélections des fortune"];

                    return {
                        ...state,
                        "combien comptes bancaires": null,
                        "Sélections des fortune": {
                            ...rest,
                        },
                    };
                }
                if (value === "Assurance 3B") {
                    invalidateStep4Errors("combien assurance 3b");
                    const { [value]: fort, ...rest } = state?.["Sélections des fortune"];

                    return {
                        ...state,
                        "combien assurance 3b": null,
                        "Sélections des fortune": {
                            ...rest,
                        },
                    };
                } else {
                    const { [value]: fort, ...rest } = state?.["Sélections des fortune"];
                    return {
                        ...state,
                        "Sélections des fortune": {
                            ...rest,
                        },
                    };
                }
            } else {
                if (value === "Aucunes") {
                    invalidateStep4Errors("combien comptes bancaires");
                    invalidateStep4Errors("combien assurance 3b");
                    invalidateStep4Errors("Sélections des fortune");
                    return {
                        ...state,
                        "combien comptes bancaires": null,
                        "combien assurance 3b": null,
                        "Sélections des fortune": { [value]: { type: value } },
                    };
                } else {
                    if (state?.["Sélections des fortune"]?.["Aucunes"]) {
                        const { Aucunes, ...rest } = state?.["Sélections des fortune"];
                        return {
                            ...state,
                            "Sélections des fortune": {
                                ...rest,

                                [value]: { type: value },
                            },
                        };
                    } else {
                        invalidateStep4Errors("Sélections des fortune");
                        return {
                            ...state,
                            "Sélections des fortune": {
                                ...state?.["Sélections des fortune"],
                                [value]: { type: value },
                            },
                        };
                    }
                }
            }
        });
    };

    const handleSelectPartner2FortuneSelection = (value) => {
        setState((state) => {
            if (
                state?.["Sélections des fortune Contribuable2"] &&
                state?.["Sélections des fortune Contribuable2"][value]
            ) {
                if (value === "Comptes Bancaires") {
                    invalidateStep4Errors("combien comptes bancaires Contribuable2");
                    const { [value]: fort, ...rest } =
                        state?.["Sélections des fortune Contribuable2"];

                    return {
                        ...state,
                        "combien comptes bancaires Contribuable2": null,
                        "Sélections des fortune Contribuable2": {
                            ...rest,
                        },
                    };
                }
                if (value === "Assurance 3B") {
                    invalidateStep4Errors("combien assurance 3b Contribuable2");
                    const { [value]: fort, ...rest } =
                        state?.["Sélections des fortune Contribuable2"];

                    return {
                        ...state,
                        "combien assurance 3b Contribuable2": null,
                        "Sélections des fortune Contribuable2": {
                            ...rest,
                        },
                    };
                } else {
                    const { [value]: fort, ...rest } =
                        state?.["Sélections des fortune Contribuable2"];
                    return {
                        ...state,
                        "Sélections des fortune Contribuable2": {
                            ...rest,
                        },
                    };
                }
            } else {
                if (value === "Aucunes") {
                    invalidateStep4Errors("combien comptes bancaires Contribuable2");
                    invalidateStep4Errors("combien assurance 3b Contribuable2");
                    invalidateStep4Errors("Sélections des fortune Contribuable2");
                    return {
                        ...state,
                        "combien comptes bancaires Contribuable2": null,
                        "combien assurance 3b Contribuable2": null,
                        "Sélections des fortune Contribuable2": {
                            [value]: { type: value },
                        },
                    };
                } else {
                    if (state?.["Sélections des fortune Contribuable2"]?.["Aucunes"]) {
                        const { Aucunes, ...rest } =
                            state?.["Sélections des fortune Contribuable2"];
                        return {
                            ...state,
                            "Sélections des fortune Contribuable2": {
                                ...rest,

                                [value]: { type: value },
                            },
                        };
                    } else {
                        invalidateStep4Errors("Sélections des fortune Contribuable2");
                        return {
                            ...state,
                            "Sélections des fortune Contribuable2": {
                                ...state?.["Sélections des fortune Contribuable2"],
                                [value]: { type: value },
                            },
                        };
                    }
                }
            }
        });
    };

    const handleSelectBankAccountsNumber = (value) => {
        setState((state) => {
            if (state?.["combien comptes bancaires"]?.number === value) {
                return {
                    ...state,
                    "combien comptes bancaires": { number: "0" },
                };
            } else {
                invalidateStep4Errors("combien comptes bancaires");
                return {
                    ...state,
                    "combien comptes bancaires": { number: value },
                };
            }
        });
    };

    const handleSelectPartner2BankAccountsNumber = (value) => {
        setState((state) => {
            if (
                state?.["combien comptes bancaires Contribuable2"]?.number === value
            ) {
                return {
                    ...state,
                    "combien comptes bancaires Contribuable2": { number: "0" },
                };
            } else {
                invalidateStep4Errors("combien comptes bancaires Contribuable2");
                return {
                    ...state,
                    "combien comptes bancaires Contribuable2": { number: value },
                };
            }
        });
    };

    const handleSelect3BAssuranceNumber = (value) => {
        setState((state) => {
            if (state?.["combien assurance 3b"]?.number === value) {
                return {
                    ...state,
                    "combien assurance 3b": { number: "0" },
                };
            } else {
                invalidateStep4Errors("combien assurance 3b");
                return {
                    ...state,
                    "combien assurance 3b": { number: value },
                };
            }
        });
    };

    const handleSelectPartner23BAssuranceNumber = (value) => {
        setState((state) => {
            if (state?.["combien assurance 3b Contribuable2"]?.number === value) {
                return {
                    ...state,
                    "combien assurance 3b Contribuable2": { number: "0" },
                };
            } else {
                invalidateStep4Errors("combien assurance 3b Contribuable2");
                return {
                    ...state,
                    "combien assurance 3b Contribuable2": { number: value },
                };
            }
        });
    };

    return (
        <div className="step4">
            <div className="refund-desc">
                <h4>{t("trefundstep4.title")}</h4>
            </div>

            <div className="checkboxes-block">
                <h4 className="checkboxes-title">
                    {t("trefundstep4.mainQuestion")}{" "}
                    {state["Situation personnelle"]?.frenchValue === "Marié" &&
                        t("part1")}
                </h4>
                <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep4.mainQuestionHelp")}</p>

                <div
                    className="single-parent-hausehold-boxes"
                    style={{ position: "relative" }}
                >
                    <ValidationError
                        error={step4ValidationErrors["Sélections des fortune"]}
                        topPosition={"-13px"}
                    />
                    <div className="no-checkbox-block" style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <div
                            className="no-checkbox"
                            onClick={() => handleSelectFortuneSelection("Assurance 3B")}
                        >
                            {state?.["Sélections des fortune"]?.["Assurance 3B"] && (
                                <div className="no-checkbox-mark">
                                    <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                    />
                                </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep4.fields.3b")}</p>
                    </div>
                    <div>
                        <p className="field-help-p">{t("trefundstep4.fields.3bHelp")}</p>
                        {state?.["Sélections des fortune"]?.["Assurance 3B"] && (
                            <div>
                                <h6 className="input-title">{t("howmany")}</h6>
                                <div
                                    className="single-parent-hausehold-boxes horisontal "
                                    style={{ position: "relative" }}
                                >
                                    <ValidationError
                                        error={step4ValidationErrors["combien assurance 3b"]}
                                        topPosition={"-13px"}
                                    />
                                    <div
                                        className="no-checkbox-block"
                                        style={{ marginRight: "15px" }}
                                    >
                                        <div
                                            className="no-checkbox"
                                            onClick={() => handleSelect3BAssuranceNumber("1")}
                                        >
                                            {state?.["combien assurance 3b"]?.number === "1" && (
                                                <div className="no-checkbox-mark">
                                                    <img
                                                        src="/checkMarkIcon.png"
                                                        alt="checkmark"
                                                        className="check-mark-icon"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <p className="no">1</p>
                                    </div>
                                    <div
                                        className="no-checkbox-block"
                                        style={{ marginRight: "15px" }}
                                    >
                                        <div
                                            className="no-checkbox"
                                            onClick={() => handleSelect3BAssuranceNumber("2")}
                                        >
                                            {state?.["combien assurance 3b"]?.number === "2" && (
                                                <div className="no-checkbox-mark">
                                                    <img
                                                        src="/checkMarkIcon.png"
                                                        alt="checkmark"
                                                        className="check-mark-icon"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <p className="no">2</p>
                                    </div>
                                    <div
                                        className="no-checkbox-block"
                                        style={{ marginRight: "15px" }}
                                    >
                                        <div
                                            className="no-checkbox"
                                            onClick={() => handleSelect3BAssuranceNumber("3")}
                                        >
                                            {state?.["combien assurance 3b"]?.number === "3" && (
                                                <div className="no-checkbox-mark">
                                                    <img
                                                        src="/checkMarkIcon.png"
                                                        alt="checkmark"
                                                        className="check-mark-icon"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <p className="no">3</p>
                                    </div>
                                    <div
                                        className="no-checkbox-block"
                                        style={{ marginRight: "15px" }}
                                    >
                                        <div
                                            className="no-checkbox"
                                            onClick={() => handleSelect3BAssuranceNumber("4")}
                                        >
                                            {state?.["combien assurance 3b"]?.number === "4" && (
                                                <div className="no-checkbox-mark">
                                                    <img
                                                        src="/checkMarkIcon.png"
                                                        alt="checkmark"
                                                        className="check-mark-icon"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <p className="no">4</p>
                                    </div>
                                    <div
                                        className="no-checkbox-block"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <div
                                            className="no-checkbox"
                                            onClick={() => handleSelect3BAssuranceNumber("Plus de 4")}
                                        >
                                            {state?.["combien assurance 3b"]?.number ===
                                                "Plus de 4" && (
                                                    <div className="no-checkbox-mark">
                                                        <img
                                                            src="/checkMarkIcon.png"
                                                            alt="checkmark"
                                                            className="check-mark-icon"
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                        <p className="no">{t("more")}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                            className="no-checkbox"
                            onClick={() => handleSelectFortuneSelection("Immeubles")}
                        >
                            {state?.["Sélections des fortune"]?.["Immeubles"] && (
                                <div className="no-checkbox-mark">
                                    <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                    />
                                </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep4.fields.rest")}</p>
                    </div>
                    <p className="field-help-p">{t("trefundstep4.fields.restHelp")}</p>
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                            className="no-checkbox"
                            onClick={() => handleSelectFortuneSelection("Comptes Bancaires")}
                        >
                            {state?.["Sélections des fortune"]?.["Comptes Bancaires"] && (
                                <div className="no-checkbox-mark">
                                    <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                    />
                                </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep4.fields.bank")}</p>
                    </div>
                    <p className="field-help-p">{t("trefundstep4.fields.bankHelp")}</p>
                    {state?.["Sélections des fortune"]?.["Comptes Bancaires"] && (
                        <div>
                            <h6 className="input-title">{t("howmany")}</h6>
                            <div
                                className="single-parent-hausehold-boxes horisontal "
                                style={{ position: "relative" }}
                            >
                                <ValidationError
                                    error={step4ValidationErrors["combien comptes bancaires"]}
                                    topPosition={"-13px"}
                                />
                                <div
                                    className="no-checkbox-block"
                                    style={{ marginRight: "15px" }}
                                >
                                    <div
                                        className="no-checkbox"
                                        onClick={() => handleSelectBankAccountsNumber("1")}
                                    >
                                        {state?.["combien comptes bancaires"]?.number === "1" && (
                                            <div className="no-checkbox-mark">
                                                <img
                                                    src="/checkMarkIcon.png"
                                                    alt="checkmark"
                                                    className="check-mark-icon"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <p className="no">1</p>
                                </div>
                                <div
                                    className="no-checkbox-block"
                                    style={{ marginRight: "15px" }}
                                >
                                    <div
                                        className="no-checkbox"
                                        onClick={() => handleSelectBankAccountsNumber("2")}
                                    >
                                        {state?.["combien comptes bancaires"]?.number === "2" && (
                                            <div className="no-checkbox-mark">
                                                <img
                                                    src="/checkMarkIcon.png"
                                                    alt="checkmark"
                                                    className="check-mark-icon"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <p className="no">2</p>
                                </div>
                                <div
                                    className="no-checkbox-block"
                                    style={{ marginRight: "15px" }}
                                >
                                    <div
                                        className="no-checkbox"
                                        onClick={() => handleSelectBankAccountsNumber("3")}
                                    >
                                        {state?.["combien comptes bancaires"]?.number === "3" && (
                                            <div className="no-checkbox-mark">
                                                <img
                                                    src="/checkMarkIcon.png"
                                                    alt="checkmark"
                                                    className="check-mark-icon"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <p className="no">3</p>
                                </div>
                                <div
                                    className="no-checkbox-block"
                                    style={{ marginRight: "15px" }}
                                >
                                    <div
                                        className="no-checkbox"
                                        onClick={() => handleSelectBankAccountsNumber("4")}
                                    >
                                        {state?.["combien comptes bancaires"]?.number === "4" && (
                                            <div className="no-checkbox-mark">
                                                <img
                                                    src="/checkMarkIcon.png"
                                                    alt="checkmark"
                                                    className="check-mark-icon"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <p className="no">4</p>
                                </div>
                                <div
                                    className="no-checkbox-block"
                                    style={{ marginBottom: "10px" }}
                                >
                                    <div
                                        className="no-checkbox"
                                        onClick={() => handleSelectBankAccountsNumber("Plus de 4")}
                                    >
                                        {state?.["combien comptes bancaires"]?.number ===
                                            "Plus de 4" && (
                                                <div className="no-checkbox-mark">
                                                    <img
                                                        src="/checkMarkIcon.png"
                                                        alt="checkmark"
                                                        className="check-mark-icon"
                                                    />
                                                </div>
                                            )}
                                    </div>
                                    <p className="no">{t("more")}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                            className="no-checkbox"
                            onClick={() => handleSelectFortuneSelection("Actions et placements")}
                        >
                            {state?.["Sélections des fortune"]?.["Actions et placements"] && (
                                <div className="no-checkbox-mark">
                                    <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                    />
                                </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep4.fields.stocksInv")}</p>
                    </div>
                    <p className="field-help-p">{t("trefundstep4.fields.stocksInvHelp")}</p>
                    <div className="no-checkbox-block">
                        <div
                            className="no-checkbox"
                            onClick={() => handleSelectFortuneSelection("Aucunes")}
                        >
                            {state?.["Sélections des fortune"]?.["Aucunes"] && (
                                <div className="no-checkbox-mark">
                                    <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                    />
                                </div>
                            )}
                        </div>
                        <p className="no">{t("none")}</p>
                    </div>
                    <p className="field-help-p">{t("trefundstep4.fields.noneHelp")}</p>
                </div>
            </div>
            {state["Situation personnelle"]?.frenchValue === "Marié" && (
                <div className="checkboxes-block">
                    <h4 className="checkboxes-title">
                        {t("trefundstep4.mainQuestion")} {t("part2")}
                    </h4>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep4.mainQuestionHelp")}</p>

                    <div
                        className="single-parent-hausehold-boxes"
                        style={{ position: "relative" }}
                    >
                        <ValidationError
                            error={
                                step4ValidationErrors["Sélections des fortune Contribuable2"]
                            }
                            topPosition={"-13px"}
                        />
                        <div className="no-checkbox-block" style={{ marginBottom: "10px", marginTop: "10px" }}>
                            <div
                                className="no-checkbox"
                                onClick={() =>
                                    handleSelectPartner2FortuneSelection("Assurance 3B")
                                }
                            >
                                {state?.["Sélections des fortune Contribuable2"]?.[
                                    "Assurance 3B"
                                ] && (
                                        <div className="no-checkbox-mark">
                                            <img
                                                src="/checkMarkIcon.png"
                                                alt="checkmark"
                                                className="check-mark-icon"
                                            />
                                        </div>
                                    )}
                            </div>
                            <p className="no">{t("trefundstep4.fields.3b")}</p>
                        </div>
                        <div>
                            <p className="field-help-p">{t("trefundstep4.fields.3bHelp")}</p>
                            {state?.["Sélections des fortune Contribuable2"]?.[
                                "Assurance 3B"
                            ] && (
                                    <div>
                                        <h6 className="input-title">{t("howmany")}</h6>
                                        <div
                                            className="single-parent-hausehold-boxes horisontal "
                                            style={{ position: "relative" }}
                                        >
                                            <ValidationError
                                                error={
                                                    step4ValidationErrors[
                                                    "combien assurance 3b Contribuable2"
                                                    ]
                                                }
                                                topPosition={"-13px"}
                                            />
                                            <div
                                                className="no-checkbox-block"
                                                style={{ marginRight: "15px" }}
                                            >
                                                <div
                                                    className="no-checkbox"
                                                    onClick={() => handleSelectPartner23BAssuranceNumber("1")}
                                                >
                                                    {state?.["combien assurance 3b Contribuable2"]?.number ===
                                                        "1" && (
                                                            <div className="no-checkbox-mark">
                                                                <img
                                                                    src="/checkMarkIcon.png"
                                                                    alt="checkmark"
                                                                    className="check-mark-icon"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <p className="no">1</p>
                                            </div>
                                            <div
                                                className="no-checkbox-block"
                                                style={{ marginRight: "15px" }}
                                            >
                                                <div
                                                    className="no-checkbox"
                                                    onClick={() => handleSelectPartner23BAssuranceNumber("2")}
                                                >
                                                    {state?.["combien assurance 3b Contribuable2"]?.number ===
                                                        "2" && (
                                                            <div className="no-checkbox-mark">
                                                                <img
                                                                    src="/checkMarkIcon.png"
                                                                    alt="checkmark"
                                                                    className="check-mark-icon"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <p className="no">2</p>
                                            </div>
                                            <div
                                                className="no-checkbox-block"
                                                style={{ marginRight: "15px" }}
                                            >
                                                <div
                                                    className="no-checkbox"
                                                    onClick={() => handleSelectPartner23BAssuranceNumber("3")}
                                                >
                                                    {state?.["combien assurance 3b Contribuable2"]?.number ===
                                                        "3" && (
                                                            <div className="no-checkbox-mark">
                                                                <img
                                                                    src="/checkMarkIcon.png"
                                                                    alt="checkmark"
                                                                    className="check-mark-icon"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <p className="no">3</p>
                                            </div>
                                            <div
                                                className="no-checkbox-block"
                                                style={{ marginRight: "15px" }}
                                            >
                                                <div
                                                    className="no-checkbox"
                                                    onClick={() => handleSelectPartner23BAssuranceNumber("4")}
                                                >
                                                    {state?.["combien assurance 3b Contribuable2"]?.number ===
                                                        "4" && (
                                                            <div className="no-checkbox-mark">
                                                                <img
                                                                    src="/checkMarkIcon.png"
                                                                    alt="checkmark"
                                                                    className="check-mark-icon"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <p className="no">4</p>
                                            </div>
                                            <div
                                                className="no-checkbox-block"
                                                style={{ marginBottom: "10px" }}
                                            >
                                                <div
                                                    className="no-checkbox"
                                                    onClick={() =>
                                                        handleSelectPartner23BAssuranceNumber("Plus de 4")
                                                    }
                                                >
                                                    {state?.["combien assurance 3b Contribuable2"]?.number ===
                                                        "Plus de 4" && (
                                                            <div className="no-checkbox-mark">
                                                                <img
                                                                    src="/checkMarkIcon.png"
                                                                    alt="checkmark"
                                                                    className="check-mark-icon"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <p className="no">{t("more")}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>

                        <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                            <div
                                className="no-checkbox"
                                onClick={() =>
                                    handleSelectPartner2FortuneSelection("Immeubles")
                                }
                            >
                                {state?.["Sélections des fortune Contribuable2"]?.[
                                    "Immeubles"
                                ] && (
                                        <div className="no-checkbox-mark">
                                            <img
                                                src="/checkMarkIcon.png"
                                                alt="checkmark"
                                                className="check-mark-icon"
                                            />
                                        </div>
                                    )}
                            </div>
                            <p className="no">{t("trefundstep4.fields.rest")}</p>
                        </div>
                        <p className="field-help-p">{t("trefundstep4.fields.restHelp")}</p>
                        <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                            <div
                                className="no-checkbox"
                                onClick={() =>
                                    handleSelectPartner2FortuneSelection("Comptes Bancaires")
                                }
                            >
                                {state?.["Sélections des fortune Contribuable2"]?.[
                                    "Comptes Bancaires"
                                ] && (
                                        <div className="no-checkbox-mark">
                                            <img
                                                src="/checkMarkIcon.png"
                                                alt="checkmark"
                                                className="check-mark-icon"
                                            />
                                        </div>
                                    )}
                            </div>
                            <p className="no">{t("trefundstep4.fields.bank")}</p>
                        </div>
                        <p className="field-help-p">{t("trefundstep4.fields.bankHelp")}</p>
                        {state?.["Sélections des fortune Contribuable2"]?.[
                            "Comptes Bancaires"
                        ] && (
                                <div>
                                    <h6 className="input-title">{t("howmany")}</h6>
                                    <div
                                        className="single-parent-hausehold-boxes horisontal "
                                        style={{ position: "relative" }}
                                    >
                                        <ValidationError
                                            error={
                                                step4ValidationErrors[
                                                "combien comptes bancaires Contribuable2"
                                                ]
                                            }
                                            topPosition={"-13px"}
                                        />
                                        <div
                                            className="no-checkbox-block"
                                            style={{ marginRight: "15px" }}
                                        >
                                            <div
                                                className="no-checkbox"
                                                onClick={() =>
                                                    handleSelectPartner2BankAccountsNumber("1")
                                                }
                                            >
                                                {state?.["combien comptes bancaires Contribuable2"]
                                                    ?.number === "1" && (
                                                        <div className="no-checkbox-mark">
                                                            <img
                                                                src="/checkMarkIcon.png"
                                                                alt="checkmark"
                                                                className="check-mark-icon"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <p className="no">1</p>
                                        </div>
                                        <div
                                            className="no-checkbox-block"
                                            style={{ marginRight: "15px" }}
                                        >
                                            <div
                                                className="no-checkbox"
                                                onClick={() =>
                                                    handleSelectPartner2BankAccountsNumber("2")
                                                }
                                            >
                                                {state?.["combien comptes bancaires Contribuable2"]
                                                    ?.number === "2" && (
                                                        <div className="no-checkbox-mark">
                                                            <img
                                                                src="/checkMarkIcon.png"
                                                                alt="checkmark"
                                                                className="check-mark-icon"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <p className="no">2</p>
                                        </div>
                                        <div
                                            className="no-checkbox-block"
                                            style={{ marginRight: "15px" }}
                                        >
                                            <div
                                                className="no-checkbox"
                                                onClick={() =>
                                                    handleSelectPartner2BankAccountsNumber("3")
                                                }
                                            >
                                                {state?.["combien comptes bancaires Contribuable2"]
                                                    ?.number === "3" && (
                                                        <div className="no-checkbox-mark">
                                                            <img
                                                                src="/checkMarkIcon.png"
                                                                alt="checkmark"
                                                                className="check-mark-icon"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <p className="no">3</p>
                                        </div>
                                        <div
                                            className="no-checkbox-block"
                                            style={{ marginRight: "15px" }}
                                        >
                                            <div
                                                className="no-checkbox"
                                                onClick={() =>
                                                    handleSelectPartner2BankAccountsNumber("4")
                                                }
                                            >
                                                {state?.["combien comptes bancaires Contribuable2"]
                                                    ?.number === "4" && (
                                                        <div className="no-checkbox-mark">
                                                            <img
                                                                src="/checkMarkIcon.png"
                                                                alt="checkmark"
                                                                className="check-mark-icon"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <p className="no">4</p>
                                        </div>
                                        <div
                                            className="no-checkbox-block"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <div
                                                className="no-checkbox"
                                                onClick={() =>
                                                    handleSelectPartner2BankAccountsNumber("Plus de 4")
                                                }
                                            >
                                                {state?.["combien comptes bancaires Contribuable2"]
                                                    ?.number === "Plus de 4" && (
                                                        <div className="no-checkbox-mark">
                                                            <img
                                                                src="/checkMarkIcon.png"
                                                                alt="checkmark"
                                                                className="check-mark-icon"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <p className="no">{t("more")}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                            <div
                                className="no-checkbox"
                                onClick={() => handleSelectPartner2FortuneSelection("Actions et placements")}
                            >
                                {state?.["Sélections des fortune Contribuable2"]?.[
                                    "Actions et placements"
                                ] && (
                                        <div className="no-checkbox-mark">
                                            <img
                                                src="/checkMarkIcon.png"
                                                alt="checkmark"
                                                className="check-mark-icon"
                                            />
                                        </div>
                                    )}
                            </div>
                            <p className="no">{t("trefundstep4.fields.stocksInv")}</p>
                        </div>
                        <p className="field-help-p">{t("trefundstep4.fields.stocksInvHelp")}</p>
                        <div className="no-checkbox-block">
                            <div
                                className="no-checkbox"
                                onClick={() => handleSelectPartner2FortuneSelection("Aucunes")}
                            >
                                {state?.["Sélections des fortune Contribuable2"]?.[
                                    "Aucunes"
                                ] && (
                                        <div className="no-checkbox-mark">
                                            <img
                                                src="/checkMarkIcon.png"
                                                alt="checkmark"
                                                className="check-mark-icon"
                                            />
                                        </div>
                                    )}
                            </div>
                            <p className="no">{t("none")}</p>
                        </div>
                        <p className="field-help-p">{t("trefundstep4.fields.noneHelp")}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Step4