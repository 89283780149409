import React, { useEffect, useState } from "react";
import "./receipt.css";
import { useTranslation } from "react-i18next";
import { useAppState } from "../../contexts/formContext";

function Receipt({ step, price }) {
  const [t, i18n] = useTranslation("global");
  const [basePrice, setBasePrice] = useState();
  const [state, setState] = useAppState();

  useEffect(() => {
    //Set base price for every one (student 50.-, single 80.-, married 100.-)
    setBasePrice(80)
    if (state["Situation professionnelle"]?.frenchValue === "Etudiant/Apprenti") {
      setBasePrice(50)
    }
    if (state["Situation personnelle"]?.frenchValue === "Marié") {
      setBasePrice(100)
    }

    //Set offers for students and simple workers
    if (state["Situation professionnelle"]?.frenchValue === "Etudiant/Apprenti") {
      if (state?.price < 50 || (state["combien comptes bancaires"]?.number === "1" && state["type de Activité"] === "Accessoires" && state["Combien Assurances maladie et accidents"]?.number === "1")) {
        setState((state) => {
          return { ...state, price: 50 };
        })
      }
    } else if (state["Situation professionnelle"]?.frenchValue !== "Etudiant/Apprenti" && state?.price < 80) {
      setState((state) => {
        return { ...state, price: 80 };
      })
    }
  }, [state]);

  return (
    <div className="receipt-wrapper">
      {!state["vegConsultClient"] && (
        <>
          <h4 className="receipt-title">
            {t("trefundstep5.reciept.rectext")}
          </h4>
          <div className="price-description">
            <div
              className="price-description-item"
              style={{ marginBottom: "12px" }}
            >
              <div className="no-checkbox-block marginZero">
                <div className="no-checkbox no-pointer">
                  <div className="no-checkbox-mark">
                    <img
                      src="/checkMarkIcon.png"
                      alt="checkmark"
                      className="check-mark-icon"
                    />
                  </div>
                </div>
                <p className="no">{t("payment.baseprice")}</p>
                <div className="info-icon">
                  <img src="/infoIcon.png" alt="compensations info" />
                  <p className="show-info" id="base-desc">
                    {t("payment.baseDescription")}
                  </p>
                </div>
              </div>
              <div className="sub-price-item">
                {basePrice}.-
              </div>
            </div>
            <div className="price-description-item">
              <div className="no-checkbox-block marginZero">
                <div className="no-checkbox no-pointer">
                  <div className="no-checkbox-mark">
                    <img
                      src="/checkMarkIcon.png"
                      alt="checkmark"
                      className="check-mark-icon"
                    />
                  </div>
                </div>
                <p className="no">{t("payment.supplement")}</p>
                <div className="info-icon">
                  <img src="/infoIcon.png" alt="compensations info" />
                  <p className="show-info" id="sup-desc">
                    {t("payment.supplementDescription")}
                  </p>
                </div>
              </div>
              <div className="sub-price-item">{state.price - basePrice}.-</div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="total-wrapper">
            <p>Total : {state.price}.- CHF</p>
          </div>
        </>
      )}

      {state["vegConsultClient"] && (
        <>
          <p style={{ marginLeft: "20px", marginRight: "20px" }}>{t("trefundstep5.reciept.vegConsultRecText")}</p>
          <p style={{ marginLeft: "20px", marginRight: "20px", marginTop: "20px" }}>{t("trefundstep5.reciept.vegConsultRecText2")}</p>
          <p style={{ marginLeft: "20px", marginRight: "20px", marginTop: "20px" }}>{t("trefundstep5.reciept.vegConsultRecText3")}</p>
        </>
      )}

    </div>
  );
}

export default Receipt;
