import React, { useEffect, useState } from "react";
import { useAppState } from "../../contexts/formContext";
import { v4 as uuidv4 } from "uuid";
import TransportationMean from "../transportationMean";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";

function Step3({
  step3ValidationErrors,
  invalidateStep3Errors,
  transportKmValidationErrors,
  setTransportKmValidationErrors,
  transportPartner2KmValidationErrors,
  setTransportPartner2KmValidationErrors,
  trainingAmountError,
  partner2TrainingAmountError,
  setTrainingAmountError,
  setPartner2TrainingAmountError,
  fromDateError,
  fromPartner2DateError,
  toDateError,
  toPartner2DateError,
  setFromDateError,
  setFromPartner2DateError,
  setToDateError,
  setToPartner2DateError,
}) {
  const [state, setState] = useAppState();
  const [partner1ShowOptions, setshowPartner1Options] = useState();
  const [showPartner2Options, setshowPartner2Options] = useState(false);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const requiredFields = [
      "au",
      "domicile",
      "du",
      "km par jour",
      "lieu de travail",
      "type",
    ];

    if (Array.isArray(state?.["frais de transport"])) {
      const r = requiredFields.every((field) =>
        state?.["frais de transport"].every((tr) => tr[field])
      );
      if (r === true) {
        invalidateStep3Errors("frais de transport data");
      }
    }

    if (
      state["Situation personnelle"]?.frenchValue === "Marié" &&
      Array.isArray(state?.["frais de transport Contribuable2"])
    ) {
      const r = requiredFields.every((field) =>
        state?.["frais de transport Contribuable2"].every((tr) => tr[field])
      );
      if (r === true) {
        invalidateStep3Errors("frais de transport Contribuable2 data");
      }
    }

    if (state?.["frais de perfectionnement montant"]) {
      invalidateStep3Errors("frais de perfectionnement montant");
    }

    if (state?.["frais de perfectionnement montant Contribuable2"]) {
      invalidateStep3Errors("frais de perfectionnement montant Contribuable2");
    }
  }, [state]);

  const showPartner1TransportationOptions = (id) => {
    setshowPartner1Options((state) => {
      if (state === id) {
        return null;
      } else {
        return id;
      }
    });
  };

  function isValidDate(date) {
    return /^\d{2}([./-])\d{2}\1\d{4}$/.test(date);
  }

  const handleAddTransportationMean = () => {
    setState((state) => {
      if (
        Array.isArray(state["frais de transport"]) ||
        !state["frais de transport"]
      ) {
        invalidateStep3Errors("frais de transport");
        return {
          ...state,
          "frais de transport":
            Array.isArray(state["frais de transport"]) &&
              state["frais de transport"]?.length > 0
              ? [...state["frais de transport"], { id: uuidv4() }]
              : [{ id: uuidv4() }],
        };
      } else {
        return state;
      }
    });
  };

  const handleAddPrtner2TransportationMean = () => {
    setState((state) => {
      if (
        Array.isArray(state["frais de transport Contribuable2"]) ||
        !state["frais de transport Contribuable2"]
      ) {
        invalidateStep3Errors("frais de transport Contribuable2");
        return {
          ...state,
          "frais de transport Contribuable2":
            Array.isArray(state["frais de transport Contribuable2"]) &&
              state["frais de transport Contribuable2"]?.length > 0
              ? [...state["frais de transport Contribuable2"], { id: uuidv4() }]
              : [{ id: uuidv4() }],
        };
      } else {
        return state;
      }
    });
  };

  const handleSelectTypeOfTransport = (value, id) => {
    setState((state) => {
      const newTransportationMeans = state["frais de transport"].map((mean) => {
        if (mean.id === id) {
          return { ...mean, type: value };
        } else {
          return mean;
        }
      });

      return { ...state, "frais de transport": newTransportationMeans };
    });
    setshowPartner1Options(null);
  };

  const handleSelectPartner2TypeOfTransport = (value, id) => {
    setState((state) => {
      const newTransportationMeans = state[
        "frais de transport Contribuable2"
      ].map((mean) => {
        if (mean.id === id) {
          return { ...mean, type: value };
        } else {
          return mean;
        }
      });

      return {
        ...state,
        "frais de transport Contribuable2": newTransportationMeans,
      };
    });
    setshowPartner2Options(false);
  };

  const handleSelectNoTransportation = () => {
    setState((state) => {
      if (state?.["frais de transport"] === "no") {
        const { ["frais de transport"]: transport, ...rest } = state;
        return { ...rest };
      } else {
        setTransportKmValidationErrors({});
        setFromDateError({});
        setToDateError({});
        invalidateStep3Errors("frais de transport");
        invalidateStep3Errors("frais de transport data");
        return { ...state, ["frais de transport"]: "no" };
      }
    });
  };

  const handleSelectPartner2NoTransportation = () => {
    setState((state) => {
      if (state?.["frais de transport Contribuable2"] === "no") {
        const { ["frais de transport Contribuable2"]: transport, ...rest } =
          state;
        return { ...rest };
      } else {
        setTransportPartner2KmValidationErrors({});
        setFromPartner2DateError({});
        setToPartner2DateError({});
        invalidateStep3Errors("frais de transport Contribuable2");
        invalidateStep3Errors("frais de transport Contribuable2 data");
        return { ...state, ["frais de transport Contribuable2"]: "no" };
      }
    });
  };
  const isValidAnyNumber = (value) => {
    return /^[0-9]*$/.test(value);
  };

  const onTransportChange = (e, key, id) => {
    if (key === "km par jour") {
      if (isValidAnyNumber(e.target.value.trimStart())) {
        setTransportKmValidationErrors((errors) => {
          if (errors) {
            const { [id]: hey, ...rest } = errors;
            return { ...rest };
          } else
            return {
              errors,
            };
        });
      }
    }

    if (key === "au") {
      if (isValidDate(e.target.value.trimStart())) {
        setToDateError((errors) => {
          const { [id]: hey, ...rest } = errors;
          return { ...rest };
        });
      }
    }
    if (key === "du") {
      if (isValidDate(e.target.value.trimStart())) {
        setFromDateError((errors) => {
          const { [id]: hey, ...rest } = errors;
          return { ...rest };
        });
      }
    }

    setState((state) => {
      const newActivities = state?.["frais de transport"].map((act) => {
        if (act.id === id) {
          return { ...act, [key]: e.target.value.trimStart() };
        } else {
          return act;
        }
      });
      return {
        ...state,
        "frais de transport": newActivities,
      };
    });
  };

  const onTransportPartner2Change = (e, key, id) => {
    if (key === "km par jour") {
      if (isValidAnyNumber(e.target.value.trimStart())) {
        setTransportPartner2KmValidationErrors((errors) => {
          const { [id]: hey, ...rest } = errors;
          return {
            ...rest,
          };
        });
      }
    }
    if (key === "au") {
      if (isValidDate(e.target.value.trimStart())) {
        setToPartner2DateError((errors) => {
          const { [id]: hey, ...rest } = errors;
          return { ...rest };
        });
      }
    }
    if (key === "du") {
      if (isValidDate(e.target.value.trimStart())) {
        setFromPartner2DateError((errors) => {
          const { [id]: hey, ...rest } = errors;
          return { ...rest };
        });
      }
    }
    setState((state) => {
      const newActivities = state?.["frais de transport Contribuable2"].map(
        (act) => {
          if (act.id === id) {
            return { ...act, [key]: e.target.value.trimStart() };
          } else {
            return act;
          }
        }
      );
      return {
        ...state,
        "frais de transport Contribuable2": newActivities,
      };
    });
  };

  const handleDeleteTransport = (id) => {
    setState((state) => {
      if (state["frais de transport"]?.length === 1) {
        setTransportKmValidationErrors({});
        const { ["frais de transport"]: tr, ...rest } = state;
        return rest;
      } else {
        setTransportKmValidationErrors((errors) => {
          const { [id]: hey, ...rest } = errors;
          return { ...rest };
        });
        setFromDateError((errors) => {
          const { [id]: hey, ...rest } = errors;
          return { ...rest };
        });
        setToDateError((errors) => {
          const { [id]: hey, ...rest } = errors;
          return { ...rest };
        });

        const newTransport = state["frais de transport"].filter((mean) => {
          return mean.id !== id;
        });
        return {
          ...state,
          "frais de transport": newTransport,
        };
      }
    });
  };

  const handleDeletePartner2Transport = (id) => {
    setState((state) => {
      if (state["frais de transport Contribuable2"]?.length === 1) {
        setTransportPartner2KmValidationErrors({});
        const { ["frais de transport Contribuable2"]: tr, ...rest } = state;
        return rest;
      } else {
        setToPartner2DateError((errors) => {
          const { [id]: hey, ...rest } = errors;
          return {
            ...rest,
          };
        });
        setFromPartner2DateError((errors) => {
          const { [id]: hey, ...rest } = errors;
          return {
            ...rest,
          };
        });
        const newTransport = state["frais de transport Contribuable2"].filter(
          (mean) => {
            return mean.id !== id;
          }
        );
        return {
          ...state,
          "frais de transport Contribuable2": newTransport,
        };
      }
    });
  };

  const handleSelectCheckBox = (key, value) => {
    setState((state) => {
      if (state[key] === value) {
        const { [key]: prop, ...rest } = state;
        return { ...rest };
      }
      return { ...state, [key]: value };
    });
  };

  const handleSelectPartner1Meal = (key, value) => {
    setState((state) => {
      if (value === "oui") {
        if (state?.[key] === value) {
          const { [key]: prop, ...rest } = state;
          invalidateStep3Errors("frais de repas contribution par l'employeur");
          return {
            ...rest,
            ["frais de repas contribution par l'employeur"]: null,
          };
        } else {
          invalidateStep3Errors(key);
          return { ...state, [key]: value };
        }
      } else if (value === "no") {
        if (state?.[key] === value) {
          const { [key]: prop, ...rest } = state;
          return {
            ...rest,
          };
        } else {
          invalidateStep3Errors(key);
          invalidateStep3Errors("frais de repas contribution par l'employeur");
          return {
            ...state,
            [key]: value,
            ["frais de repas contribution par l'employeur"]: null,
          };
        }
      }
    });
  };

  const handleSelectPartner1MealType = (value) => {
    setState((state) => {
      if (value === "oui") {
        if (state?.["frais de repas contribution par l'employeur"] === value) {
          const {
            ["frais de repas contribution par l'employeur"]: prop,
            ...rest
          } = state;
          invalidateStep3Errors("frais de repas contribution par l'employeur");
          return {
            ...rest,
          };
        } else {
          invalidateStep3Errors("frais de repas contribution par l'employeur");
          return {
            ...state,
            ["frais de repas contribution par l'employeur"]: value,
          };
        }
      } else if (value === "no") {
        if (state?.["frais de repas contribution par l'employeur"] === value) {
          const {
            ["frais de repas contribution par l'employeur"]: prop,
            ...rest
          } = state;
          return {
            ...rest,
          };
        } else {
          invalidateStep3Errors("frais de repas contribution par l'employeur");
          return {
            ...state,
            ["frais de repas contribution par l'employeur"]: value,
          };
        }
      }
    });
  };

  const handleSelectPartner2Meal = (key, value) => {
    setState((state) => {
      if (value === "oui") {
        if (state?.[key] === value) {
          const { [key]: prop, ...rest } = state;
          invalidateStep3Errors(
            "frais de repas contribution par l'employeur Contribuable2"
          );
          return {
            ...rest,
            ["frais de repas contribution par l'employeur Contribuable2"]: null,
          };
        } else {
          invalidateStep3Errors(key);
          return { ...state, [key]: value };
        }
      } else if (value === "no") {
        if (state?.[key] === value) {
          const { [key]: prop, ...rest } = state;
          return {
            ...rest,
          };
        } else {
          invalidateStep3Errors(key);
          invalidateStep3Errors(
            "frais de repas contribution par l'employeur Contribuable2"
          );
          return {
            ...state,
            [key]: value,
            ["frais de repas contribution par l'employeur Contribuable2"]: null,
          };
        }
      }
    });
  };

  const handleSelectPartner2MealType = (value) => {
    setState((state) => {
      if (value === "oui") {
        if (
          state?.[
          "frais de repas contribution par l'employeur Contribuable2"
          ] === value
        ) {
          const {
            ["frais de repas contribution par l'employeur Contribuable2"]: prop,
            ...rest
          } = state;
          invalidateStep3Errors(
            "frais de repas contribution par l'employeur Contribuable2"
          );
          return {
            ...rest,
          };
        } else {
          invalidateStep3Errors(
            "frais de repas contribution par l'employeur Contribuable2"
          );
          return {
            ...state,
            ["frais de repas contribution par l'employeur Contribuable2"]:
              value,
          };
        }
      } else if (value === "no") {
        if (
          state?.[
          "frais de repas contribution par l'employeur Contribuable2"
          ] === value
        ) {
          const {
            ["frais de repas contribution par l'employeur Contribuable2"]: prop,
            ...rest
          } = state;
          return {
            ...rest,
          };
        } else {
          invalidateStep3Errors(
            "frais de repas contribution par l'employeur Contribuable2"
          );
          return {
            ...state,
            ["frais de repas contribution par l'employeur Contribuable2"]:
              value,
          };
        }
      }
    });
  };

  const handleSelectTrainingExpenses = (value) => {
    setState((state) => {
      if (value === "oui") {
        if (state?.["frais de perfectionnement"] === "oui") {
          setTrainingAmountError(false);
          invalidateStep3Errors("frais de perfectionnement montant");
          const {
            "frais de perfectionnement": train,
            "frais de perfectionnement montant": am,
            ...rest
          } = state;
          return rest;
        } else {
          invalidateStep3Errors("frais de perfectionnement");
          return {
            ...state,
            "frais de perfectionnement": "oui",
            "frais de perfectionnement montant": "",
          };
        }
      } else {
        if (state?.["frais de perfectionnement"] === "no") {
          invalidateStep3Errors("frais de perfectionnement montant");
          const { "frais de perfectionnement": train, ...rest } = state;
          return rest;
        } else {
          setTrainingAmountError(false);
          invalidateStep3Errors("frais de perfectionnement montant");
          invalidateStep3Errors("frais de perfectionnement");
          const { "frais de perfectionnement montant": am, ...rest } = state;
          return { ...rest, "frais de perfectionnement": "no" };
        }
      }
    });
  };

  const handleSelectPartner2TrainingExpenses = (value) => {
    setState((state) => {
      if (value === "oui") {
        invalidateStep3Errors(
          "frais de perfectionnement montant Contribuable2"
        );

        if (state?.["frais de perfectionnement Contribuable2"] === "oui") {
          setPartner2TrainingAmountError(false);
          const {
            "frais de perfectionnement Contribuable2": train,
            "frais de perfectionnement montant Contribuable2": am,
            ...rest
          } = state;
          return rest;
        } else {
          invalidateStep3Errors("frais de perfectionnement Contribuable2");
          return {
            ...state,
            "frais de perfectionnement Contribuable2": "oui",
            "frais de perfectionnement montant Contribuable2": "",
          };
        }
      } else {
        if (state?.["frais de perfectionnement Contribuable2"] === "no") {
          invalidateStep3Errors(
            "frais de perfectionnement montant Contribuable2"
          );
          const { "frais de perfectionnement Contribuable2": train, ...rest } =
            state;
          return rest;
        } else {
          setPartner2TrainingAmountError(false);
          invalidateStep3Errors(
            "frais de perfectionnement montant Contribuable2"
          );
          invalidateStep3Errors("frais de perfectionnement Contribuable2");
          const {
            "frais de perfectionnement montant Contribuable2": am,
            ...rest
          } = state;
          return { ...rest, "frais de perfectionnement Contribuable2": "no" };
        }
      }
    });
  };

  const handleChangeTrainingExpenses = (e) => {
    if (isValidAnyNumber(e.target.value.trimStart())) {
      setTrainingAmountError(false);
    }
    setState((state) => {
      return {
        ...state,
        "frais de perfectionnement montant": e.target.value.trimStart(),
      };
    });
  };

  const handleChangePartner2TrainingExpenses = (e) => {
    if (isValidAnyNumber(e.target.value.trimStart())) {
      setPartner2TrainingAmountError(false);
    }
    setState((state) => {
      return {
        ...state,
        "frais de perfectionnement montant Contribuable2":
          e.target.value.trimStart(),
      };
    });
  };

  return (
    <div>
      <div className="refund-desc">
        <h4>{t("trefundstep3.title")}</h4>
      </div>

      <h4 className="checkboxes-title" style={{ marginBottom: "20px" }}>
        {state["Situation personnelle"]?.frenchValue === "Marié" &&
          t("part1")}
      </h4>

      <ol type="A" style={{ marginBottom: "50px" }}>
        <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep3.mainList.transport")}
          <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
            <li style={{ fontSize: "16px", fontStyle: "italic" }}>{t("trefundstep3.mainList.helpTransport")}
              <div className="add-btn-block" style={{ position: "relative", marginBottom: "15px", fontStyle: "normal" }}>
                <ValidationError
                  error={step3ValidationErrors["frais de transport"]}
                  topPosition={"-15px"}
                />
                <button
                  className="add-btn"
                  onClick={handleAddTransportationMean}
                  type="button"
                >
                  +{t("Add")}
                </button>
                <div
                  className="no-checkbox-block marginZero"
                  style={{ position: "relative" }}
                >
                  <div className="no-checkbox" onClick={handleSelectNoTransportation}>
                    {state?.["frais de transport"] === "no" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("no")}</p>
                </div>
              </div>
              {state?.["frais de transport"] &&
                Array.isArray(state?.["frais de transport"]) && (
                  <div style={{ position: "relative" }}>
                    <ValidationError
                      error={step3ValidationErrors["frais de transport data"]}
                      topPosition={"-10px"}
                      message={t("reqfields")}
                    />
                    {state?.["frais de transport"].map((mean, index) => {
                      return (
                        <TransportationMean
                          key={mean.id}
                          id={mean.id}
                          index={index}
                          handleSelectTypeOfTransport={handleSelectTypeOfTransport}
                          value={mean?.type?.value}
                          showOptions={partner1ShowOptions}
                          setShowOptions={showPartner1TransportationOptions}
                          onChange={onTransportChange}
                          mean={mean}
                          handleDeleteTransport={handleDeleteTransport}
                          transportKmValidationErrors={transportKmValidationErrors}
                          fromDateError={fromDateError}
                          toDateError={toDateError}
                        />
                      );
                    })}
                  </div>
                )}
            </li>
          </ul>
        </li>
        <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep3.mainList.dinner")}
          <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
            <li style={{ fontSize: "16px", fontStyle: "italic" }}>{t("trefundstep3.mainList.helpDinner")}
              <div
                className="single-parent-hausehold-boxes"
                style={{ position: "relative", marginBottom: "15px", fontStyle: "normal" }}
              >
                <ValidationError
                  error={step3ValidationErrors["frais de repas"]}
                  topPosition={"-12px"}
                />

                <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                  <div
                    className="no-checkbox"
                    onClick={() => handleSelectPartner1Meal("frais de repas", "oui")}
                  >
                    {state?.["frais de repas"] === "oui" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("yes")}</p>
                </div>
                {state?.["frais de repas"] === "oui" && (
                  <div>
                    <h6 className="input-title">
                      {t("trefundstep3.fields.empcontr")}
                    </h6>
                    <div
                      className="single-parent-hausehold-boxes horisontal "
                      style={{
                        position: "relative",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ValidationError
                        topPosition={"-10px"}
                        error={
                          step3ValidationErrors[
                          "frais de repas contribution par l'employeur"
                          ]
                        }
                      />
                      <div
                        className="no-checkbox-block"
                        style={{ marginBottom: "10px", marginRight: "15px" }}
                      >
                        <div
                          className="no-checkbox"
                          onClick={() => handleSelectPartner1MealType("oui")}
                        >
                          {state?.["frais de repas contribution par l'employeur"] ===
                            "oui" && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no" style={{ fontStyle: "normal" }}>{t("yes")}</p>
                      </div>
                      <div
                        className="no-checkbox-block"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          className="no-checkbox"
                          onClick={() => handleSelectPartner1MealType("no")}
                        >
                          {state?.["frais de repas contribution par l'employeur"] ===
                            "no" && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no">{t("no")}</p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                  <div
                    className="no-checkbox"
                    onClick={() => handleSelectPartner1Meal("frais de repas", "no")}
                  >
                    {state?.["frais de repas"] === "no" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("no")}</p>
                </div>
              </div>
            </li>
          </ul>
        </li>
        <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep3.mainList.training")}
          <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
            <li style={{ fontSize: "16px", fontStyle: "italic" }}>{t("trefundstep3.mainList.helpTraining")}
              <div
                className="single-parent-hausehold-boxes"
                style={{ position: "relative", marginBottom: "15px", fontStyle: "normal" }}
              >
                <ValidationError
                  error={step3ValidationErrors["frais de perfectionnement"]}
                  topPosition={"-13px"}
                />
                <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                  <div
                    className="no-checkbox"
                    onClick={() => handleSelectTrainingExpenses("oui")}
                  >
                    {state?.["frais de perfectionnement"] === "oui" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("yes")}</p>
                </div>
                <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                  <div
                    className="no-checkbox"
                    onClick={() => handleSelectTrainingExpenses("no")}
                  >
                    {state?.["frais de perfectionnement"] === "no" && (
                      <div className="no-checkbox-mark">
                        <img
                          src="/checkMarkIcon.png"
                          alt="checkmark"
                          className="check-mark-icon"
                        />
                      </div>
                    )}
                  </div>
                  <p className="no">{t("no")}</p>
                </div>
                {state?.["frais de perfectionnement"] === "oui" &&
                  state?.["frais de perfectionnement montant"] !== null && (
                    <div
                      className="activity-input-wrapper"
                      style={{ position: "relative", paddingTop: "5px" }}
                    >
                      <ValidationError
                        error={
                          step3ValidationErrors["frais de perfectionnement montant"]
                        }
                        topPosition={"-10px"}
                      />
                      <ValidationError
                        error={trainingAmountError}
                        topPosition={"-10px"}
                        message="Numbers only"
                      />
                      <input
                        className="activity-input"
                        type="text"
                        placeholder={t("trefundstep2.fields.amount")}
                        onChange={(e) => handleChangeTrainingExpenses(e)}
                        value={state?.["frais de perfectionnement montant"] || ""}
                      />
                    </div>
                  )}
              </div>
            </li>
          </ul>
        </li>
      </ol>

      {state["Situation personnelle"]?.frenchValue === "Marié" && (
        <>
          <h4 className="checkboxes-title" style={{ marginBottom: "20px" }}>
            {state["Situation personnelle"]?.frenchValue === "Marié" &&
              t("part2")}
          </h4>
          <ol type="A" style={{ marginBottom: "50px" }}>
            <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep3.mainList.transport")}
              <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                <li style={{ fontSize: "16px", fontStyle: "italic" }}>{t("trefundstep3.mainList.helpTransport")}
                  <div className="checkboxes-block" style={{ position: "relative", marginBottom: "15px", fontStyle: "normal" }}>
                    <ValidationError
                      error={step3ValidationErrors["frais de transport Contribuable2"]}
                      topPosition={"-15px"}
                    />
                    <div className="add-btn-block">
                      <button
                        className="add-btn"
                        onClick={handleAddPrtner2TransportationMean}
                        type="button"
                      >
                        +{t("Add")}
                      </button>
                      <div className="no-checkbox-block marginZero">
                        <div
                          className="no-checkbox"
                          onClick={handleSelectPartner2NoTransportation}
                        >
                          {state?.["frais de transport Contribuable2"] === "no" && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                        </div>
                        <p className="no">{t("no")}</p>
                      </div>
                    </div>
                  </div>
                  {state?.["frais de transport Contribuable2"] &&
                    Array.isArray(state?.["frais de transport Contribuable2"]) && (
                      <div style={{ position: "relative" }}>
                        <ValidationError
                          error={
                            step3ValidationErrors[
                            "frais de transport Contribuable2 data"
                            ]
                          }
                          topPosition={"-10px"}
                          message={t("reqfields")}
                        />
                        {state?.["frais de transport Contribuable2"].map(
                          (mean, index) => {
                            return (
                              <TransportationMean
                                key={mean.id}
                                id={mean.id}
                                index={index}
                                handleSelectTypeOfTransport={
                                  handleSelectPartner2TypeOfTransport
                                }
                                value={mean?.type?.value}
                                showOptions={showPartner2Options}
                                setShowOptions={setshowPartner2Options}
                                onChange={onTransportPartner2Change}
                                mean={mean}
                                handleDeleteTransport={handleDeletePartner2Transport}
                                transportPartner2KmValidationErrors={
                                  transportPartner2KmValidationErrors
                                }
                                fromPartner2DateError={fromPartner2DateError}
                                toPartner2DateError={toPartner2DateError}
                              />
                            );
                          }
                        )}
                      </div>
                    )}
                </li>
              </ul>
            </li>
            <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep3.mainList.dinner")}
              <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                <li style={{ fontSize: "16px", fontStyle: "italic" }}>{t("trefundstep3.mainList.helpDinner")}
                  <div
                    className="single-parent-hausehold-boxes"
                    style={{ position: "relative", marginBottom: "15px", fontStyle: "normal" }}
                  >
                    <ValidationError
                      error={step3ValidationErrors["frais de repas Contribuable2"]}
                      topPosition={"-12px"}
                    />

                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() =>
                          handleSelectPartner2Meal(
                            "frais de repas Contribuable2",
                            "oui"
                          )
                        }
                      >
                        {state?.["frais de repas Contribuable2"] === "oui" && (
                          <div className="no-checkbox-mark">
                            <img
                              src="/checkMarkIcon.png"
                              alt="checkmark"
                              className="check-mark-icon"
                            />
                          </div>
                        )}
                      </div>
                      <p className="no">{t("yes")}</p>
                    </div>
                    {state?.["frais de repas Contribuable2"] === "oui" && (
                      <div>
                        <h6 className="input-title">
                          {t("trefundstep3.fields.empcontr")}
                        </h6>
                        <div
                          className="single-parent-hausehold-boxes horisontal "
                          style={{
                            position: "relative",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <ValidationError
                            topPosition={"-10px"}
                            error={
                              step3ValidationErrors[
                              "frais de repas contribution par l'employeur Contribuable2"
                              ]
                            }
                          />
                          <div
                            className="no-checkbox-block"
                            style={{ marginBottom: "10px", marginRight: "15px" }}
                          >
                            <div
                              className="no-checkbox"
                              onClick={() => handleSelectPartner2MealType("oui")}
                            >
                              {state?.[
                                "frais de repas contribution par l'employeur Contribuable2"
                              ] === "oui" && (
                                  <div className="no-checkbox-mark">
                                    <img
                                      src="/checkMarkIcon.png"
                                      alt="checkmark"
                                      className="check-mark-icon"
                                    />
                                  </div>
                                )}
                            </div>
                            <p className="no">{t("yes")}</p>
                          </div>
                          <div
                            className="no-checkbox-block"
                            style={{ marginBottom: "10px" }}
                          >
                            <div
                              className="no-checkbox"
                              onClick={() => handleSelectPartner2MealType("no")}
                            >
                              {state?.[
                                "frais de repas contribution par l'employeur Contribuable2"
                              ] === "no" && (
                                  <div className="no-checkbox-mark">
                                    <img
                                      src="/checkMarkIcon.png"
                                      alt="checkmark"
                                      className="check-mark-icon"
                                    />
                                  </div>
                                )}
                            </div>
                            <p className="no">{t("no")}</p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() =>
                          handleSelectPartner2Meal("frais de repas Contribuable2", "no")
                        }
                      >
                        {state?.["frais de repas Contribuable2"] === "no" && (
                          <div className="no-checkbox-mark">
                            <img
                              src="/checkMarkIcon.png"
                              alt="checkmark"
                              className="check-mark-icon"
                            />
                          </div>
                        )}
                      </div>
                      <p className="no">{t("no")}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep3.mainList.training")}
              <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                <li style={{ fontSize: "16px", fontStyle: "italic" }}>{t("trefundstep3.mainList.helpTraining")}
                  <div
                    className="single-parent-hausehold-boxes"
                    style={{ position: "relative", marginBottom: "15px", fontStyle: "normal" }}
                  >
                    <ValidationError
                      error={
                        step3ValidationErrors["frais de perfectionnement Contribuable2"]
                      }
                      topPosition={"-13px"}
                    />
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() => handleSelectPartner2TrainingExpenses("oui")}
                      >
                        {state?.["frais de perfectionnement Contribuable2"] ===
                          "oui" && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                      </div>
                      <p className="no">{t("yes")}</p>
                    </div>
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() => handleSelectPartner2TrainingExpenses("no")}
                      >
                        {state?.["frais de perfectionnement Contribuable2"] ===
                          "no" && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                      </div>
                      <p className="no">{t("no")}</p>
                    </div>
                    {state?.["frais de perfectionnement Contribuable2"] === "oui" &&
                      state?.["frais de perfectionnement montant Contribuable2"] !==
                      null && (
                        <div
                          className="activity-input-wrapper"
                          style={{ position: "relative", paddingTop: "5px" }}
                        >
                          <ValidationError
                            error={
                              step3ValidationErrors[
                              "frais de perfectionnement montant Contribuable2"
                              ]
                            }
                            topPosition={"-10px"}
                          />
                          <ValidationError
                            error={partner2TrainingAmountError}
                            topPosition={"-10px"}
                            message="Numbers only"
                          />
                          <input
                            className="activity-input"
                            type="text"
                            placeholder={t("trefundstep2.fields.amount")}
                            onChange={(e) => handleChangePartner2TrainingExpenses(e)}
                            value={
                              state?.[
                              "frais de perfectionnement montant Contribuable2"
                              ] || ""
                            }
                          />
                        </div>
                      )}
                  </div>
                </li>
              </ul>
            </li>
          </ol>
        </>
      )}
    </div>
  );
}

export default Step3;
