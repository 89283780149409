import React, { useEffect, useState } from "react";
import { useAppState } from "../../contexts/formContext";
import CustomSelect from "../Customselect";
import Receipt from "../receipt";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";
import url from "../../constants";
import axios from "axios";
import "./step5.css";

function Step5({
  step5ValidationErrors,
  invalidateStep5Errors,
  personalInformationValidationErrors,
  invalidatePersonalInformation,
  emailError,
  invalidateEmail,
  goToPayment,
  alimAmountPartner2Step5Error,
  alimAmountStep5Error,
  setAlimAmountStep5Error,
  setAlimAmountPartner2Step5Error,
  invalidatePhone,
  phoneError,
}) {
  const [state, setState] = useAppState();
  const [showOptions, setShowOptions] = useState(false);
  const [showPartner2Options, setShowPartner2Options] = useState(false);
  const [showReceipt, setshowReceipt] = useState(false);
  const [t, i18n] = useTranslation("global");

  const alimonyOptions = [
    { id: 1, value: "Child", frenchValue: "Enfant" },
    { id: 2, value: "Ex-spouse", frenchValue: "Ex-conjoint" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const save = () => {
    axios
      .post(
        `${url}save-form`,
        {
          test: "test",
        },
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
        console.log("inside catch");
      });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidPhone(phone) {
    return /^[+]*(?:\d[+]*){6,12}$/.test(phone);
  }

  useEffect(() => {
    setshowReceipt(checkIfShowReceipt(state));

    let alRes = false;
    let alRes2 = false;
    let persRes = false;
    let emRes = false;
    let phoneRes = false;
    const requiredFields = [
      "Nom",
      "Prenom",
      "Adresse",
      "Domicile",
      "Montant",
      "lien de parentes",
    ];

    const persInformationFields = [
      "name and lastname",
      "emai",
      "mobile",
      "address",
    ];

    persInformationFields.forEach((info) => {
      if (state?.personalInformation?.[info]) {
        persRes = true;
      } else {
        persRes = false;
      }
    });

    if (persRes === true) {
      invalidatePersonalInformation();
    }

    if (isValidEmail(state?.personalInformation?.email)) {
      emRes = true;
    }

    if (isValidPhone(state?.personalInformation?.mobile)) {
      phoneRes = true;
    }

    if (emRes === true) {
      invalidateEmail();
    }

    if (phoneRes === true) {
      invalidatePhone();
    }

    requiredFields.forEach((field) => {
      if (state?.["Sélections des deductions Pensions alimentaires"]?.[field]) {
        alRes = true;
      } else {
        alRes = false;
      }
    });
    if (alRes === true) {
      invalidateStep5Errors("Sélections des deductions Pensions alimentaires");
    }

    if (state["Situation personnelle"]?.frenchValue === "Marié") {
      requiredFields.forEach((field) => {
        if (
          state?.[
          "Sélections des deductions Pensions alimentaires Contribuable2"
          ]?.[field]
        ) {
          alRes2 = true;
        } else {
          alRes2 = false;
        }
      });
      if (alRes2 === true) {
        invalidateStep5Errors(
          "Sélections des deductions Pensions alimentaires Contribuable2"
        );
      }
    }
  }, [state]);

  const handleSelectAlimonyOptions = (value) => {
    setState((state) => {
      return {
        ...state,
        ["Sélections des deductions Pensions alimentaires"]: {
          ...state["Sélections des deductions Pensions alimentaires"],
          ["lien de parentes"]: value,
        },
      };
    });
    setShowOptions(false);
  };

  const handleSelectPartner2AlimonyOptions = (value) => {
    setState((state) => {
      return {
        ...state,
        ["Sélections des deductions Pensions alimentaires Contribuable2"]: {
          ...state[
          "Sélections des deductions Pensions alimentaires Contribuable2"
          ],
          ["lien de parentes"]: value,
        },
      };
    });
    setShowPartner2Options(false);
  };

  const onAlimonyChange = (e, key) => {
    if (key === "Montant" && isValidAnyNumber(e.target.value.trimStart())) {
      setAlimAmountStep5Error(false);
    }
    setState((state) => {
      return {
        ...state,
        "Sélections des deductions Pensions alimentaires": {
          ...state?.["Sélections des deductions Pensions alimentaires"],
          [key]: e.target.value.trimStart(),
        },
      };
    });
  };

  const onPartner2AlimonyChange = (e, key) => {
    if (key === "Montant" && isValidAnyNumber(e.target.value.trimStart())) {
      setAlimAmountPartner2Step5Error(false);
    }
    setState((state) => {
      return {
        ...state,
        "Sélections des deductions Pensions alimentaires Contribuable2": {
          ...state?.[
          "Sélections des deductions Pensions alimentaires Contribuable2"
          ],
          [key]: e.target.value.trimStart(),
        },
      };
    });
  };

  const handleSelectDeduction = (value) => {
    setState((state) => {
      if (
        state?.["Sélections de déductions"] &&
        state?.["Sélections de déductions"][value]
      ) {
        if (value === "Pensions alimentaires") {
          setAlimAmountStep5Error(false);
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions"];
          invalidateStep5Errors(
            "Sélections des deductions Pensions alimentaires"
          );

          return {
            ...state,
            "Sélections des deductions Pensions alimentaires": null,
            "Sélections de déductions": {
              ...rest,
            },
          };
        }
        if (value === "Cotisatoin OPP3 (3ème pilier A)") {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions"];
          invalidateStep5Errors("combien OPP3");

          return {
            ...state,
            "combien OPP3": null,
            "Sélections de déductions": {
              ...rest,
            },
          };
        }
        if (value === "Frais médicaux et dentaires") {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions"];
          invalidateStep5Errors("Combien Frais médicaux et dentaires");

          return {
            ...state,
            "Combien Frais médicaux et dentaires": null,
            "Sélections de déductions": {
              ...rest,
            },
          };
        }
        if (value === "Dons") {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions"];
          invalidateStep5Errors("Combien Dons");

          return {
            ...state,
            "Combien Dons": null,
            "Sélections de déductions": {
              ...rest,
            },
          };
        }
        if (value === "Assurances maladie et accidents") {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions"];
          invalidateStep5Errors("Combien Assurances maladie et accidents");

          return {
            ...state,
            "Combien Assurances maladie et accidents": null,
            "Sélections de déductions": {
              ...rest,
            },
          };
        } else {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions"];
          return {
            ...state,
            "Sélections de déductions": {
              ...rest,
            },
          };
        }
      } else {
        if (value === "Aucunes") {
          invalidateStep5Errors(
            "Sélections des deductions Pensions alimentaires"
          );

          invalidateStep5Errors("combien OPP3");
          invalidateStep5Errors("Sélections de déductions");
          invalidateStep5Errors("Combien Dons");
          invalidateStep5Errors("Combien Frais médicaux et dentaires");
          invalidateStep5Errors("Combien Assurances maladie et accidents");
          setAlimAmountStep5Error(false);
          return {
            ...state,
            "Sélections des deductions Pensions alimentaires": null,
            "combien OPP3": null,
            "Combien Dons": null,
            "Combien Frais médicaux et dentaires": null,
            "Combien Assurances maladie et accidents": null,
            "Sélections de déductions": { [value]: { type: value } },
          };
        } else {
          if (state?.["Sélections de déductions"]?.["Aucunes"]) {
            const { Aucunes, ...rest } = state?.["Sélections de déductions"];
            return {
              ...state,
              "Sélections de déductions": {
                ...rest,

                [value]: { type: value },
              },
            };
          } else {
            invalidateStep5Errors("Sélections de déductions");
            return {
              ...state,
              "Sélections de déductions": {
                ...state?.["Sélections de déductions"],
                [value]: { type: value },
              },
            };
          }
        }
      }
    });
  };

  const handleSelectPartner2Deduction = (value) => {
    setState((state) => {
      if (
        state?.["Sélections de déductions Contribuable2"] &&
        state?.["Sélections de déductions Contribuable2"][value]
      ) {
        if (value === "Pensions alimentaires") {
          setAlimAmountPartner2Step5Error(false);
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions Contribuable2"];
          invalidateStep5Errors(
            "Sélections des deductions Pensions alimentaires Contribuable2"
          );
          return {
            ...state,
            "Sélections des deductions Pensions alimentaires Contribuable2":
              null,
            "Sélections de déductions Contribuable2": {
              ...rest,
            },
          };
        }
        if (value === "Cotisatoin OPP3 (3ème pilier A)") {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions Contribuable2"];
          invalidateStep5Errors("combien OPP3 Contribuable2");
          return {
            ...state,
            "combien OPP3 Contribuable2": null,
            "Sélections de déductions Contribuable2": {
              ...rest,
            },
          };
        }
        if (value === "Frais médicaux et dentaires") {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions Contribuable2"];
          invalidateStep5Errors(
            "Combien Frais médicaux et dentaires Contribuable2"
          );
          return {
            ...state,
            "Combien Frais médicaux et dentaires Contribuable2": null,
            "Sélections de déductions Contribuable2": {
              ...rest,
            },
          };
        }
        if (value === "Dons") {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions Contribuable2"];
          invalidateStep5Errors("Combien Dons Contribuable2");
          return {
            ...state,
            "Combien Dons Contribuable2": null,
            "Sélections de déductions Contribuable2": {
              ...rest,
            },
          };
        }
        if (value === "Assurances maladie et accidents") {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions Contribuable2"];
          invalidateStep5Errors(
            "Combien Assurances maladie et accidents Contribuable2"
          );
          return {
            ...state,
            "Combien Assurances maladie et accidents Contribuable2": null,
            "Sélections de déductions Contribuable2": {
              ...rest,
            },
          };
        } else {
          const { [value]: fort, ...rest } =
            state?.["Sélections de déductions Contribuable2"];
          return {
            ...state,
            "Sélections de déductions Contribuable2": {
              ...rest,
            },
          };
        }
      } else {
        if (value === "Aucunes") {
          setAlimAmountPartner2Step5Error(false);
          invalidateStep5Errors("Sélections de déductions Contribuable2");
          invalidateStep5Errors(
            "Sélections des deductions Pensions alimentaires Contribuable2"
          );
          invalidateStep5Errors("combien OPP3 Contribuable2");
          invalidateStep5Errors("Combien Dons Contribuable2");
          invalidateStep5Errors(
            "Combien Frais médicaux et dentaires Contribuable2"
          );
          invalidateStep5Errors(
            "Combien Assurances maladie et accidents Contribuable2"
          );
          return {
            ...state,
            "Sélections des deductions Pensions alimentaires Contribuable2":
              null,
            "combien OPP3 Contribuable2": null,
            "Combien Dons Contribuable2": null,
            "Combien Frais médicaux et dentaires Contribuable2": null,
            "Combien Assurances maladie et accidents Contribuable2": null,
            "Sélections de déductions Contribuable2": {
              [value]: { type: value },
            },
          };
        } else {
          if (state?.["Sélections de déductions Contribuable2"]?.["Aucunes"]) {
            const { Aucunes, ...rest } =
              state?.["Sélections de déductions Contribuable2"];
            return {
              ...state,
              "Sélections de déductions Contribuable2": {
                ...rest,

                [value]: { type: value },
              },
            };
          } else {
            invalidateStep5Errors("Sélections de déductions Contribuable2");
            return {
              ...state,
              "Sélections de déductions Contribuable2": {
                ...state?.["Sélections de déductions Contribuable2"],
                [value]: { type: value },
              },
            };
          }
        }
      }
    });
  };

  const handleSelectOPP3Number = (value) => {
    setState((state) => {
      if (state?.["combien OPP3"]?.number === value) {
        return {
          ...state,
          "combien OPP3": { number: "0" },
        };
      } else {
        invalidateStep5Errors("combien OPP3");
        return {
          ...state,
          "combien OPP3": { number: value },
        };
      }
    });
  };

  const handleSelectPartner2OPP3Number = (value) => {
    setState((state) => {
      if (state?.["combien OPP3 Contribuable2"]?.number === value) {
        return {
          ...state,
          "combien OPP3 Contribuable2": { number: "0" },
        };
      } else {
        invalidateStep5Errors("combien OPP3 Contribuable2");
        return {
          ...state,
          "combien OPP3 Contribuable2": { number: value },
        };
      }
    });
  };

  const handleSelectMedicalAndDentalNumber = (value) => {
    setState((state) => {
      if (state?.["Combien Frais médicaux et dentaires"]?.number === value) {
        return {
          ...state,
          "Combien Frais médicaux et dentaires": { number: "0" },
        };
      } else {
        invalidateStep5Errors("Combien Frais médicaux et dentaires");
        return {
          ...state,
          "Combien Frais médicaux et dentaires": { number: value },
        };
      }
    });
  };

  const handleSelectPartner2MedicalAndDentalNumber = (value) => {
    setState((state) => {
      if (
        state?.["Combien Frais médicaux et dentaires Contribuable2"]?.number ===
        value
      ) {
        return {
          ...state,
          "Combien Frais médicaux et dentaires Contribuable2": { number: "0" },
        };
      } else {
        invalidateStep5Errors(
          "Combien Frais médicaux et dentaires Contribuable2"
        );
        return {
          ...state,
          "Combien Frais médicaux et dentaires Contribuable2": {
            number: value,
          },
        };
      }
    });
  };

  const handleSelectDonationsNumber = (value) => {
    setState((state) => {
      if (state?.["Combien Dons"]?.number === value) {
        return {
          ...state,
          "Combien Dons": { number: "0" },
        };
      } else {
        invalidateStep5Errors("Combien Dons");
        return {
          ...state,
          "Combien Dons": { number: value },
        };
      }
    });
  };

  const isValidAnyNumber = (value) => {
    return /^[0-9]*$/.test(value);
  };

  const handleSelectPartner2DonationsNumber = (value) => {
    setState((state) => {
      if (state?.["Combien Dons Contribuable2"]?.number === value) {
        return {
          ...state,
          "Combien Dons Contribuable2": { number: "0" },
        };
      } else {
        invalidateStep5Errors("Combien Dons Contribuable2");
        return {
          ...state,
          "Combien Dons Contribuable2": { number: value },
        };
      }
    });
  };

  const handleSelectAccidentInsuranceNumber = (value) => {
    setState((state) => {
      if (
        state?.["Combien Assurances maladie et accidents"]?.number === value
      ) {
        return {
          ...state,
          "Combien Assurances maladie et accidents": { number: "0" },
        };
      } else {
        invalidateStep5Errors("Combien Assurances maladie et accidents");
        return {
          ...state,
          "Combien Assurances maladie et accidents": { number: value },
        };
      }
    });
  };

  const handleSelectPartner2AccidentInsuranceNumber = (value) => {
    setState((state) => {
      if (
        state?.["Combien Assurances maladie et accidents Contribuable2"]
          ?.number === value
      ) {
        return {
          ...state,
          "Combien Assurances maladie et accidents Contribuable2": {
            number: "0",
          },
        };
      } else {
        invalidateStep5Errors(
          "Combien Assurances maladie et accidents Contribuable2"
        );
        return {
          ...state,
          "Combien Assurances maladie et accidents Contribuable2": {
            number: value,
          },
        };
      }
    });
  };

  const checkIfShowReceipt = (form) => {
    let result = false;

    // if (
    //   form["Sélections de déductions"] &&
    //   Object.keys(form["Sélections de déductions"]).length > 0
    // ) {
    //   result = true;
    // }

    if (form["Sélections de déductions"]) {
      if (Object.keys(form["Sélections de déductions"]).length > 0) {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }

    // else {
    //   result = false;
    // }

    if (state["Situation personnelle"]?.frenchValue === "Marié") {
      if (form["Sélections de déductions Contribuable2"]) {
        if (
          Object.keys(form["Sélections de déductions Contribuable2"]).length > 0
        ) {
          result = true;
        } else {
          result = false;
        }
      }
    }

    // if (
    //   form["Sélections de déductions Contribuable2"] &&
    //   Object.keys(form["Sélections de déductions Contribuable2"]).length > 0
    // ) {
    //   result = true;
    // }

    // else {
    //   result = false;
    // }

    if (form["Sélections de déductions"]?.["Cotisatoin OPP3 (3ème pilier A)"]) {
      if (
        !state["combien OPP3"] ||
        state["combien OPP3"]?.number === "Plus de 4"
      ) {
        result = false;
      }
    }

    if (
      form["Sélections de déductions Contribuable2"]?.[
      "Cotisatoin OPP3 (3ème pilier A)"
      ]
    ) {
      if (
        !form["combien OPP3 Contribuable2"] ||
        form["combien OPP3 Contribuable2"]?.number === "Plus de 4"
      ) {
        result = false;
      }
    }

    if (form["Sélections de déductions"]?.["Frais médicaux et dentaires"]) {
      if (
        !form["Combien Frais médicaux et dentaires"] ||
        form["Combien Frais médicaux et dentaires"]?.number === "Plus de 4"
      ) {
        result = false;
      }
    }

    if (
      form["Sélections de déductions Contribuable2"]?.[
      "Frais médicaux et dentaires"
      ]
    ) {
      if (
        !form["Combien Frais médicaux et dentaires Contribuable2"] ||
        form["Combien Frais médicaux et dentaires Contribuable2"]?.number ===
        "Plus de 4"
      ) {
        result = false;
      }
    }

    if (form["Sélections de déductions"]?.["Dons"]) {
      if (
        !form["Combien Dons"] ||
        form["Combien Dons"]?.number === "Plus de 4"
      ) {
        result = false;
      }
    }

    if (form["Sélections de déductions Contribuable2"]?.["Dons"]) {
      if (
        !form["Combien Dons Contribuable2"] ||
        form["Combien Dons Contribuable2"]?.number === "Plus de 4"
      ) {
        result = false;
      }
    }

    if (form["Sélections de déductions"]?.["Assurances maladie et accidents"]) {
      if (
        !form["Combien Assurances maladie et accidents"] ||
        form["Combien Assurances maladie et accidents"]?.number === "Plus de 4"
      ) {
        result = false;
      }
    }

    if (
      form["Sélections de déductions Contribuable2"]?.[
      "Assurances maladie et accidents"
      ]
    ) {
      if (
        !form["Combien Assurances maladie et accidents Contribuable2"] ||
        form["Combien Assurances maladie et accidents Contribuable2"]
          ?.number === "Plus de 4"
      ) {
        result = false;
      }
    }

    return result;
  };

  const handleChangePersonalEnformation = (key, e) => {
    setState((state) => {
      return {
        ...state,
        personalInformation: {
          ...state.personalInformation,
          [key]: e.target.value.trimStart(),
        },
      };
    });
  };

  return (
    <div className="step5">
      <div className="refund-desc">
        <h4>{t("trefundstep5.title")}</h4>
      </div>

      <div className="checkboxes-block">
        <h4 className="checkboxes-title">
          {t("trefundstep5.mainQuestion")}{" "}
          {state["Situation personnelle"]?.frenchValue === "Marié" &&
            t("part1")}
        </h4>
        <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.mainQuestionHelp")}</p>

        <div
          className="single-parent-hausehold-boxes"
          style={{ position: "relative" }}
        >
          <ValidationError
            error={step5ValidationErrors["Sélections de déductions"]}
            topPosition={"-13px"}
          />
          <ol type="A">
            <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.savings")}
              <p style={{ fontSize: "16px", marginTop: "10px" }}>{t("trefundstep5.mainList.savingsHelp")}</p>
              <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                <li>
                  <div className="single-parent-hausehold-boxes">
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() =>
                          handleSelectDeduction("Cotisatoin OPP3 (3ème pilier A)")
                        }
                      >
                        {state["Sélections de déductions"]?.[
                          "Cotisatoin OPP3 (3ème pilier A)"
                        ] && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                      </div>
                      <p className="no">{t("trefundstep5.fields.opp3")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.opp3Help")}</p>
                    {state?.["Sélections de déductions"]?.[
                      "Cotisatoin OPP3 (3ème pilier A)"
                    ] && (
                        <div>
                          <h6 className="input-title">{t("howmany")}</h6>
                          <div
                            className="single-parent-hausehold-boxes horisontal "
                            style={{ position: "relative" }}
                          >
                            <ValidationError
                              error={step5ValidationErrors["combien OPP3"]}
                              topPosition={"-13px"}
                            />
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectOPP3Number("1")}
                              >
                                {state?.["combien OPP3"]?.number === "1" && (
                                  <div className="no-checkbox-mark">
                                    <img
                                      src="/checkMarkIcon.png"
                                      alt="checkmark"
                                      className="check-mark-icon"
                                    />
                                  </div>
                                )}
                              </div>
                              <p className="no">1</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectOPP3Number("2")}
                              >
                                {state?.["combien OPP3"]?.number === "2" && (
                                  <div className="no-checkbox-mark">
                                    <img
                                      src="/checkMarkIcon.png"
                                      alt="checkmark"
                                      className="check-mark-icon"
                                    />
                                  </div>
                                )}
                              </div>
                              <p className="no">2</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectOPP3Number("3")}
                              >
                                {state?.["combien OPP3"]?.number === "3" && (
                                  <div className="no-checkbox-mark">
                                    <img
                                      src="/checkMarkIcon.png"
                                      alt="checkmark"
                                      className="check-mark-icon"
                                    />
                                  </div>
                                )}
                              </div>
                              <p className="no">3</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectOPP3Number("4")}
                              >
                                {state?.["combien OPP3"]?.number === "4" && (
                                  <div className="no-checkbox-mark">
                                    <img
                                      src="/checkMarkIcon.png"
                                      alt="checkmark"
                                      className="check-mark-icon"
                                    />
                                  </div>
                                )}
                              </div>
                              <p className="no">4</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginBottom: "10px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectOPP3Number("Plus de 4")}
                              >
                                {state?.["combien OPP3"]?.number === "Plus de 4" && (
                                  <div className="no-checkbox-mark">
                                    <img
                                      src="/checkMarkIcon.png"
                                      alt="checkmark"
                                      className="check-mark-icon"
                                    />
                                  </div>
                                )}
                              </div>
                              <p className="no">{t("more")}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() =>
                          handleSelectDeduction("Rachats dannées dassurances")
                        }
                      >
                        {state["Sélections de déductions"]?.[
                          "Rachats dannées dassurances"
                        ] && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                      </div>
                      <p className="no">{t("trefundstep5.fields.ins")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.insHelp")}</p>
                  </div>
                </li>
              </ul>
            </li>
            <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.health")}
              <p style={{ fontSize: "16px", marginTop: "10px" }}>{t("trefundstep5.mainList.healthHelp")}</p>
              <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                <li>
                  <div className="single-parent-hausehold-boxes">
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() =>
                          handleSelectDeduction("Frais médicaux et dentaires")
                        }
                      >
                        {state["Sélections de déductions"]?.[
                          "Frais médicaux et dentaires"
                        ] && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                      </div>
                      <p className="no">{t("trefundstep5.fields.med")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.medHelp")}</p>
                    {state?.["Sélections de déductions"]?.[
                      "Frais médicaux et dentaires"
                    ] && (
                        <div>
                          <h6 className="input-title">{t("howmany")}</h6>
                          <div
                            className="single-parent-hausehold-boxes horisontal "
                            style={{ position: "relative" }}
                          >
                            <ValidationError
                              error={
                                step5ValidationErrors["Combien Frais médicaux et dentaires"]
                              }
                              topPosition={"-13px"}
                            />
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectMedicalAndDentalNumber("1")}
                              >
                                {state?.["Combien Frais médicaux et dentaires"]?.number ===
                                  "1" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">1</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectMedicalAndDentalNumber("2")}
                              >
                                {state?.["Combien Frais médicaux et dentaires"]?.number ===
                                  "2" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">2</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectMedicalAndDentalNumber("3")}
                              >
                                {state?.["Combien Frais médicaux et dentaires"]?.number ===
                                  "3" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">3</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectMedicalAndDentalNumber("4")}
                              >
                                {state?.["Combien Frais médicaux et dentaires"]?.number ===
                                  "4" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">4</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginBottom: "10px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() =>
                                  handleSelectMedicalAndDentalNumber("Plus de 4")
                                }
                              >
                                {state?.["Combien Frais médicaux et dentaires"]?.number ===
                                  "Plus de 4" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">{t("more")}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() =>
                          handleSelectDeduction("Assurances maladie et accidents")
                        }
                      >
                        {state["Sélections de déductions"]?.[
                          "Assurances maladie et accidents"
                        ] && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                      </div>
                      <p className="no">{t("trefundstep5.fields.health")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.healthHelp")}</p>
                    {state?.["Sélections de déductions"]?.[
                      "Assurances maladie et accidents"
                    ] && (
                        <div>
                          <h6 className="input-title">{t("howmany")}</h6>
                          <div
                            className="single-parent-hausehold-boxes horisontal "
                            style={{ position: "relative" }}
                          >
                            <ValidationError
                              error={
                                step5ValidationErrors[
                                "Combien Assurances maladie et accidents"
                                ]
                              }
                              topPosition={"-13px"}
                            />
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectAccidentInsuranceNumber("1")}
                              >
                                {state?.["Combien Assurances maladie et accidents"]
                                  ?.number === "1" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">1</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectAccidentInsuranceNumber("2")}
                              >
                                {state?.["Combien Assurances maladie et accidents"]
                                  ?.number === "2" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">2</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectAccidentInsuranceNumber("3")}
                              >
                                {state?.["Combien Assurances maladie et accidents"]
                                  ?.number === "3" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">3</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectAccidentInsuranceNumber("4")}
                              >
                                {state?.["Combien Assurances maladie et accidents"]
                                  ?.number === "4" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">4</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginBottom: "10px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() =>
                                  handleSelectAccidentInsuranceNumber("Plus de 4")
                                }
                              >
                                {state?.["Combien Assurances maladie et accidents"]
                                  ?.number === "Plus de 4" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">{t("more")}</p>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </li>
              </ul>
            </li>
            <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.givings")}
              <p style={{ fontSize: "16px", marginTop: "10px" }}>{t("trefundstep5.mainList.givingsHelp")}</p>
              <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                <li>
                  <div className="single-parent-hausehold-boxes">
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() => handleSelectDeduction("Pensions alimentaires")}
                      >
                        {state["Sélections de déductions"]?.["Pensions alimentaires"] && (
                          <div className="no-checkbox-mark">
                            <img
                              src="/checkMarkIcon.png"
                              alt="checkmark"
                              className="check-mark-icon"
                            />
                          </div>
                        )}
                      </div>
                      <p className="no">{t("trefundstep5.fields.alim")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.alimHelp")}</p>
                    {state["Sélections de déductions"]?.["Pensions alimentaires"] && (
                      <div
                        className="alimony-inputs-wrapper"
                        style={{ position: "relative" }}
                      >
                        <ValidationError
                          error={
                            step5ValidationErrors[
                            "Sélections des deductions Pensions alimentaires"
                            ]
                          }
                          topPosition={"-10px"}
                          message={t("reqfields")}
                        />
                        <h6 className="input-title">{t("trefundstep5.fields.paidto")}</h6>
                        <div className="alimony-input-wrapper ">
                          <input
                            className="activity-input"
                            type="text"
                            placeholder={t("trefundstep5.fields.name")}
                            onChange={(e) => onAlimonyChange(e, "Nom")}
                            value={
                              state?.[
                              "Sélections des deductions Pensions alimentaires"
                              ]?.["Nom"] || ""
                            }
                          />
                        </div>

                        <div className="alimony-input-wrapper ">
                          <input
                            className="activity-input"
                            type="text"
                            placeholder={t("trefundstep5.fields.lname")}
                            onChange={(e) => onAlimonyChange(e, "Prenom")}
                            value={
                              state?.[
                              "Sélections des deductions Pensions alimentaires"
                              ]?.["Prenom"] || ""
                            }
                          />
                        </div>
                        <div className="alimony-input-wrapper ">
                          <input
                            className="activity-input"
                            type="text"
                            placeholder={t("trefundstep2.fields.addr")}
                            onChange={(e) => onAlimonyChange(e, "Adresse")}
                            value={
                              state?.[
                              "Sélections des deductions Pensions alimentaires"
                              ]?.["Adresse"] || ""
                            }
                          />
                        </div>
                        <div className="alimony-input-wrapper">
                          <input
                            className="activity-input"
                            type="text"
                            placeholder={t("trefundstep2.fields.res")}
                            onChange={(e) => onAlimonyChange(e, "Domicile")}
                            value={
                              state?.[
                              "Sélections des deductions Pensions alimentaires"
                              ]?.["Domicile"] || ""
                            }
                          />
                        </div>
                        <div
                          className="alimony-input-wrapper"
                          style={{ position: "relative" }}
                        >
                          <ValidationError
                            error={alimAmountStep5Error}
                            topPosition={"-12px"}
                            message="Numbers only"
                          />
                          <input
                            className="activity-input"
                            type="text"
                            placeholder={t("trefundstep2.fields.yearamount")}
                            onChange={(e) => onAlimonyChange(e, "Montant")}
                            value={
                              state?.[
                              "Sélections des deductions Pensions alimentaires"
                              ]?.["Montant"] || ""
                            }
                          />
                        </div>
                        <CustomSelect
                          onChange={handleSelectAlimonyOptions}
                          value={
                            i18n.language === "en"
                              ? state?.[
                                "Sélections des deductions Pensions alimentaires"
                              ]?.["lien de parentes"]?.value
                              : state?.[
                                "Sélections des deductions Pensions alimentaires"
                              ]?.["lien de parentes"]?.frenchValue
                          }
                          showOptions={showOptions}
                          setShowOptions={setShowOptions}
                          label={t("trefundstep5.fields.rel")}
                          selectedValueWidth="100%"
                          options={alimonyOptions}
                        // handleResetValue={handleResetValue}
                        />
                      </div>
                    )}
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() => handleSelectDeduction("Dons")}
                      >
                        {state["Sélections de déductions"]?.["Dons"] && (
                          <div className="no-checkbox-mark">
                            <img
                              src="/checkMarkIcon.png"
                              alt="checkmark"
                              className="check-mark-icon"
                            />
                          </div>
                        )}
                      </div>
                      <p className="no">{t("trefundstep5.fields.don")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.donHelp")}</p>
                    {state?.["Sélections de déductions"]?.["Dons"] && (
                      <div>
                        <h6 className="input-title">{t("howmany")}</h6>
                        <div
                          className="single-parent-hausehold-boxes horisontal "
                          style={{ position: "relative" }}
                        >
                          <ValidationError
                            error={step5ValidationErrors["Combien Dons"]}
                            topPosition={"-13px"}
                          />
                          <div
                            className="no-checkbox-block"
                            style={{ marginRight: "15px" }}
                          >
                            <div
                              className="no-checkbox"
                              onClick={() => handleSelectDonationsNumber("1")}
                            >
                              {state?.["Combien Dons"]?.number === "1" && (
                                <div className="no-checkbox-mark">
                                  <img
                                    src="/checkMarkIcon.png"
                                    alt="checkmark"
                                    className="check-mark-icon"
                                  />
                                </div>
                              )}
                            </div>
                            <p className="no">1</p>
                          </div>
                          <div
                            className="no-checkbox-block"
                            style={{ marginRight: "15px" }}
                          >
                            <div
                              className="no-checkbox"
                              onClick={() => handleSelectDonationsNumber("2")}
                            >
                              {state?.["Combien Dons"]?.number === "2" && (
                                <div className="no-checkbox-mark">
                                  <img
                                    src="/checkMarkIcon.png"
                                    alt="checkmark"
                                    className="check-mark-icon"
                                  />
                                </div>
                              )}
                            </div>
                            <p className="no">2</p>
                          </div>
                          <div
                            className="no-checkbox-block"
                            style={{ marginRight: "15px" }}
                          >
                            <div
                              className="no-checkbox"
                              onClick={() => handleSelectDonationsNumber("3")}
                            >
                              {state?.["Combien Dons"]?.number === "3" && (
                                <div className="no-checkbox-mark">
                                  <img
                                    src="/checkMarkIcon.png"
                                    alt="checkmark"
                                    className="check-mark-icon"
                                  />
                                </div>
                              )}
                            </div>
                            <p className="no">3</p>
                          </div>
                          <div
                            className="no-checkbox-block"
                            style={{ marginRight: "15px" }}
                          >
                            <div
                              className="no-checkbox"
                              onClick={() => handleSelectDonationsNumber("4")}
                            >
                              {state?.["Combien Dons"]?.number === "4" && (
                                <div className="no-checkbox-mark">
                                  <img
                                    src="/checkMarkIcon.png"
                                    alt="checkmark"
                                    className="check-mark-icon"
                                  />
                                </div>
                              )}
                            </div>
                            <p className="no">4</p>
                          </div>
                          <div
                            className="no-checkbox-block"
                            style={{ marginBottom: "10px" }}
                          >
                            <div
                              className="no-checkbox"
                              onClick={() => handleSelectDonationsNumber("Plus de 4")}
                            >
                              {state?.["Combien Dons"]?.number === "Plus de 4" && (
                                <div className="no-checkbox-mark">
                                  <img
                                    src="/checkMarkIcon.png"
                                    alt="checkmark"
                                    className="check-mark-icon"
                                  />
                                </div>
                              )}
                            </div>
                            <p className="no">{t("more")}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </li>
            <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.support")}
              <p style={{ fontSize: "16px", marginTop: "10px" }}>{t("trefundstep5.mainList.supportHelp")}</p>
              <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                <li>
                  <div className="single-parent-hausehold-boxes">
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() => handleSelectDeduction("Déductions logement")}
                      >
                        {state["Sélections de déductions"]?.["Déductions logement"] && (
                          <div className="no-checkbox-mark">
                            <img
                              src="/checkMarkIcon.png"
                              alt="checkmark"
                              className="check-mark-icon"
                            />
                          </div>
                        )}
                      </div>
                      <p className="no">{t("trefundstep5.fields.house")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.houseHelp")}</p>
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() => handleSelectDeduction("Frais de garde")}
                      >
                        {state["Sélections de déductions"]?.["Frais de garde"] && (
                          <div className="no-checkbox-mark">
                            <img
                              src="/checkMarkIcon.png"
                              alt="checkmark"
                              className="check-mark-icon"
                            />
                          </div>
                        )}
                      </div>
                      <p className="no">{t("trefundstep5.fields.child")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.childHelp")}</p>
                  </div>
                </li>
              </ul>
            </li>
            <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.noExpanses")}
              <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                <li>
                  <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                    <div
                      className="no-checkbox"
                      onClick={() => handleSelectDeduction("Aucunes")}
                    >
                      {state["Sélections de déductions"]?.["Aucunes"] && (
                        <div className="no-checkbox-mark">
                          <img
                            src="/checkMarkIcon.png"
                            alt="checkmark"
                            className="check-mark-icon"
                          />
                        </div>
                      )}
                    </div>
                    <p className="no">{t("none")}</p>
                  </div>
                  <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.noneHelp")}</p>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      {state["Situation personnelle"]?.frenchValue === "Marié" && (
        <div className="checkboxes-block">
          <h4 className="checkboxes-title">
            {t("trefundstep5.mainQuestion")} {t("part2")}
          </h4>
          <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.mainQuestionHelp")}</p>

          <div
            className="single-parent-hausehold-boxes"
            style={{ position: "relative" }}
          >
            <ValidationError
              error={
                step5ValidationErrors["Sélections de déductions Contribuable2"]
              }
              topPosition={"-13px"}
            />

            <ol type="A">
              <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.savings")}
                <p style={{ fontSize: "16px", marginTop: "10px" }}>{t("trefundstep5.mainList.savingsHelp")}</p>
                <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                  <li>
                    <div className="single-parent-hausehold-boxes">
                      <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                          className="no-checkbox"
                          onClick={() =>
                            handleSelectPartner2Deduction(
                              "Cotisatoin OPP3 (3ème pilier A)"
                            )
                          }
                        >
                          {state["Sélections de déductions Contribuable2"]?.[
                            "Cotisatoin OPP3 (3ème pilier A)"
                          ] && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep5.fields.opp3")}</p>
                      </div>
                      <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.opp3Help")}</p>
                      {state?.["Sélections de déductions Contribuable2"]?.[
                        "Cotisatoin OPP3 (3ème pilier A)"
                      ] && (
                          <div>
                            <h6 className="input-title">{t("howmany")}</h6>
                            <div
                              className="single-parent-hausehold-boxes horisontal "
                              style={{ position: "relative" }}
                            >
                              <ValidationError
                                error={step5ValidationErrors["combien OPP3 Contribuable2"]}
                                topPosition={"-13px"}
                              />
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() => handleSelectPartner2OPP3Number("1")}
                                >
                                  {state?.["combien OPP3 Contribuable2"]?.number ===
                                    "1" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">1</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() => handleSelectPartner2OPP3Number("2")}
                                >
                                  {state?.["combien OPP3 Contribuable2"]?.number ===
                                    "2" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">2</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() => handleSelectPartner2OPP3Number("3")}
                                >
                                  {state?.["combien OPP3 Contribuable2"]?.number ===
                                    "3" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">3</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() => handleSelectPartner2OPP3Number("4")}
                                >
                                  {state?.["combien OPP3 Contribuable2"]?.number ===
                                    "4" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">4</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginBottom: "10px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2OPP3Number("Plus de 4")
                                  }
                                >
                                  {state?.["combien OPP3 Contribuable2"]?.number ===
                                    "Plus de 4" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">{t("more")}</p>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                          className="no-checkbox"
                          onClick={() =>
                            handleSelectPartner2Deduction("Rachats dannées dassurances")
                          }
                        >
                          {state["Sélections de déductions Contribuable2"]?.[
                            "Rachats dannées dassurances"
                          ] && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep5.fields.ins")}</p>
                      </div>
                      <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.insHelp")}</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.health")}
                <p style={{ fontSize: "16px", marginTop: "10px" }}>{t("trefundstep5.mainList.healthHelp")}</p>
                <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                  <li>
                    <div className="single-parent-hausehold-boxes">
                      <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                          className="no-checkbox"
                          onClick={() =>
                            handleSelectPartner2Deduction("Frais médicaux et dentaires")
                          }
                        >
                          {state["Sélections de déductions Contribuable2"]?.[
                            "Frais médicaux et dentaires"
                          ] && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep5.fields.med")}</p>
                      </div>
                      <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.medHelp")}</p>
                      {state?.["Sélections de déductions Contribuable2"]?.[
                        "Frais médicaux et dentaires"
                      ] && (
                          <div>
                            <h6 className="input-title">{t("howmany")}</h6>
                            <div
                              className="single-parent-hausehold-boxes horisontal "
                              style={{ position: "relative" }}
                            >
                              <ValidationError
                                error={
                                  step5ValidationErrors[
                                  "Combien Frais médicaux et dentaires Contribuable2"
                                  ]
                                }
                                topPosition={"-13px"}
                              />
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2MedicalAndDentalNumber("1")
                                  }
                                >
                                  {state?.[
                                    "Combien Frais médicaux et dentaires Contribuable2"
                                  ]?.number === "1" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">1</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2MedicalAndDentalNumber("2")
                                  }
                                >
                                  {state?.[
                                    "Combien Frais médicaux et dentaires Contribuable2"
                                  ]?.number === "2" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">2</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2MedicalAndDentalNumber("3")
                                  }
                                >
                                  {state?.[
                                    "Combien Frais médicaux et dentaires Contribuable2"
                                  ]?.number === "3" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">3</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2MedicalAndDentalNumber("4")
                                  }
                                >
                                  {state?.[
                                    "Combien Frais médicaux et dentaires Contribuable2"
                                  ]?.number === "4" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">4</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginBottom: "10px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2MedicalAndDentalNumber("Plus de 4")
                                  }
                                >
                                  {state?.[
                                    "Combien Frais médicaux et dentaires Contribuable2"
                                  ]?.number === "Plus de 4" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">{t("more")}</p>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                          className="no-checkbox"
                          onClick={() =>
                            handleSelectPartner2Deduction(
                              "Assurances maladie et accidents"
                            )
                          }
                        >
                          {state["Sélections de déductions Contribuable2"]?.[
                            "Assurances maladie et accidents"
                          ] && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep5.fields.health")}</p>
                      </div>
                      <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.healthHelp")}</p>
                      {state?.["Sélections de déductions Contribuable2"]?.[
                        "Assurances maladie et accidents"
                      ] && (
                          <div>
                            <h6 className="input-title">{t("howmany")}</h6>
                            <div
                              className="single-parent-hausehold-boxes horisontal "
                              style={{ position: "relative" }}
                            >
                              <ValidationError
                                error={
                                  step5ValidationErrors[
                                  "Combien Assurances maladie et accidents Contribuable2"
                                  ]
                                }
                                topPosition={"-13px"}
                              />
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2AccidentInsuranceNumber("1")
                                  }
                                >
                                  {state?.[
                                    "Combien Assurances maladie et accidents Contribuable2"
                                  ]?.number === "1" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">1</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2AccidentInsuranceNumber("2")
                                  }
                                >
                                  {state?.[
                                    "Combien Assurances maladie et accidents Contribuable2"
                                  ]?.number === "2" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">2</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2AccidentInsuranceNumber("3")
                                  }
                                >
                                  {state?.[
                                    "Combien Assurances maladie et accidents Contribuable2"
                                  ]?.number === "3" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">3</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginRight: "15px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2AccidentInsuranceNumber("4")
                                  }
                                >
                                  {state?.[
                                    "Combien Assurances maladie et accidents Contribuable2"
                                  ]?.number === "4" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">4</p>
                              </div>
                              <div
                                className="no-checkbox-block"
                                style={{ marginBottom: "10px" }}
                              >
                                <div
                                  className="no-checkbox"
                                  onClick={() =>
                                    handleSelectPartner2AccidentInsuranceNumber("Plus de 4")
                                  }
                                >
                                  {state?.[
                                    "Combien Assurances maladie et accidents Contribuable2"
                                  ]?.number === "Plus de 4" && (
                                      <div className="no-checkbox-mark">
                                        <img
                                          src="/checkMarkIcon.png"
                                          alt="checkmark"
                                          className="check-mark-icon"
                                        />
                                      </div>
                                    )}
                                </div>
                                <p className="no">{t("more")}</p>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </li>
                </ul>
              </li>
              <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.givings")}
                <p style={{ fontSize: "16px", marginTop: "10px" }}>{t("trefundstep5.mainList.givingsHelp")}</p>
                <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                  <li>
                    <div className="single-parent-hausehold-boxes">
                      <div
                        className="no-checkbox-block"
                        style={{ marginBottom: "10px", position: "relative" }}
                      >
                        <div
                          className="no-checkbox"
                          onClick={() =>
                            handleSelectPartner2Deduction("Pensions alimentaires")
                          }
                        >
                          {state["Sélections de déductions Contribuable2"]?.[
                            "Pensions alimentaires"
                          ] && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep5.fields.alim")}</p>
                      </div>
                      <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.alimHelp")}</p>
                      {state["Sélections de déductions Contribuable2"]?.[
                        "Pensions alimentaires"
                      ] && (
                          <div
                            className="alimony-inputs-wrapper"
                            style={{ position: "relative" }}
                          >
                            <ValidationError
                              error={
                                step5ValidationErrors[
                                "Sélections des deductions Pensions alimentaires Contribuable2"
                                ]
                              }
                              topPosition={"-10px"}
                              message={t("reqfields")}
                            />
                            <h6 className="input-title">
                              {t("trefundstep5.fields.paidto")}
                            </h6>
                            <div className="alimony-input-wrapper ">
                              <input
                                className="activity-input"
                                type="text"
                                placeholder={t("trefundstep5.fields.name")}
                                onChange={(e) => onPartner2AlimonyChange(e, "Nom")}
                                value={
                                  state?.[
                                  "Sélections des deductions Pensions alimentaires Contribuable2"
                                  ]?.["Nom"] || ""
                                }
                              />
                            </div>
                            <div className="alimony-input-wrapper ">
                              <input
                                className="activity-input"
                                type="text"
                                placeholder={t("trefundstep5.fields.lname")}
                                onChange={(e) => onPartner2AlimonyChange(e, "Prenom")}
                                value={
                                  state?.[
                                  "Sélections des deductions Pensions alimentaires Contribuable2"
                                  ]?.["Prenom"] || ""
                                }
                              />
                            </div>
                            <div className="alimony-input-wrapper ">
                              <input
                                className="activity-input"
                                type="text"
                                placeholder={t("trefundstep2.fields.addr")}
                                onChange={(e) => onPartner2AlimonyChange(e, "Adresse")}
                                value={
                                  state?.[
                                  "Sélections des deductions Pensions alimentaires Contribuable2"
                                  ]?.["Adresse"] || ""
                                }
                              />
                            </div>
                            <div className="alimony-input-wrapper">
                              <input
                                className="activity-input"
                                type="text"
                                placeholder={t("trefundstep2.fields.res")}
                                onChange={(e) => onPartner2AlimonyChange(e, "Domicile")}
                                value={
                                  state?.[
                                  "Sélections des deductions Pensions alimentaires Contribuable2"
                                  ]?.["Domicile"] || ""
                                }
                              />
                            </div>
                            <div
                              className="alimony-input-wrapper "
                              style={{ position: "relative" }}
                            >
                              <ValidationError
                                error={alimAmountPartner2Step5Error}
                                topPosition={"-12px"}
                                message="Numbers only"
                              />
                              <input
                                className="activity-input"
                                type="text"
                                placeholder={t("trefundstep2.fields.yearamount")}
                                onChange={(e) => onPartner2AlimonyChange(e, "Montant")}
                                value={
                                  state?.[
                                  "Sélections des deductions Pensions alimentaires Contribuable2"
                                  ]?.["Montant"] || ""
                                }
                              />
                            </div>
                            <CustomSelect
                              onChange={handleSelectPartner2AlimonyOptions}
                              value={
                                i18n.language === "en"
                                  ? state?.[
                                    "Sélections des deductions Pensions alimentaires Contribuable2"
                                  ]?.["lien de parentes"]?.value
                                  : state?.[
                                    "Sélections des deductions Pensions alimentaires Contribuable2"
                                  ]?.["lien de parentes"]?.frenchValue
                              }
                              showOptions={showPartner2Options}
                              setShowOptions={setShowPartner2Options}
                              label={t("trefundstep5.fields.rel")}
                              selectedValueWidth="100%"
                              options={alimonyOptions}
                            // handleResetValue={handleResetValue}
                            />
                          </div>
                        )}
                      <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                          className="no-checkbox"
                          onClick={() => handleSelectPartner2Deduction("Dons")}
                        >
                          {state["Sélections de déductions Contribuable2"]?.["Dons"] && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                        </div>
                        <p className="no">{t("trefundstep5.fields.don")}</p>
                      </div>
                      <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.donHelp")}</p>
                      {state?.["Sélections de déductions Contribuable2"]?.["Dons"] && (
                        <div>
                          <h6 className="input-title">{t("howmany")}</h6>
                          <div
                            className="single-parent-hausehold-boxes horisontal "
                            style={{ position: "relative" }}
                          >
                            <ValidationError
                              error={step5ValidationErrors["Combien Dons Contribuable2"]}
                              topPosition={"-13px"}
                            />
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectPartner2DonationsNumber("1")}
                              >
                                {state?.["Combien Dons Contribuable2"]?.number ===
                                  "1" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">1</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectPartner2DonationsNumber("2")}
                              >
                                {state?.["Combien Dons Contribuable2"]?.number ===
                                  "2" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">2</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectPartner2DonationsNumber("3")}
                              >
                                {state?.["Combien Dons Contribuable2"]?.number ===
                                  "3" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">3</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginRight: "15px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() => handleSelectPartner2DonationsNumber("4")}
                              >
                                {state?.["Combien Dons Contribuable2"]?.number ===
                                  "4" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">4</p>
                            </div>
                            <div
                              className="no-checkbox-block"
                              style={{ marginBottom: "10px" }}
                            >
                              <div
                                className="no-checkbox"
                                onClick={() =>
                                  handleSelectPartner2DonationsNumber("Plus de 4")
                                }
                              >
                                {state?.["Combien Dons Contribuable2"]?.number ===
                                  "Plus de 4" && (
                                    <div className="no-checkbox-mark">
                                      <img
                                        src="/checkMarkIcon.png"
                                        alt="checkmark"
                                        className="check-mark-icon"
                                      />
                                    </div>
                                  )}
                              </div>
                              <p className="no">{t("more")}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </li>
              <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.support")}
                <p style={{ fontSize: "16px", marginTop: "10px" }}>{t("trefundstep5.mainList.supportHelp")}</p>
                <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                  <li>
                    <div className="single-parent-hausehold-boxes">
                      <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                          className="no-checkbox"
                          onClick={() =>
                            handleSelectPartner2Deduction("Déductions logement")
                          }
                        >
                          {state["Sélections de déductions Contribuable2"]?.[
                            "Déductions logement"
                          ] && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep5.fields.house")}</p>
                      </div>
                      <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.houseHelp")}</p>
                      <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                        <div
                          className="no-checkbox"
                          onClick={() => handleSelectPartner2Deduction("Frais de garde")}
                        >
                          {state["Sélections de déductions Contribuable2"]?.[
                            "Frais de garde"
                          ] && (
                              <div className="no-checkbox-mark">
                                <img
                                  src="/checkMarkIcon.png"
                                  alt="checkmark"
                                  className="check-mark-icon"
                                />
                              </div>
                            )}
                        </div>
                        <p className="no">{t("trefundstep5.fields.child")}</p>
                      </div>
                      <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.childHelp")}</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li style={{ fontSize: "20px", fontFamily: "Nunito SemiBold", marginBottom: "30px", marginLeft: "24px" }}>{t("trefundstep5.mainList.noExpanses")}
                <ul style={{ paddingLeft: "25px", marginTop: "15px" }}>
                  <li>
                    <div className="no-checkbox-block" style={{ marginBottom: "10px" }}>
                      <div
                        className="no-checkbox"
                        onClick={() => handleSelectPartner2Deduction("Aucunes")}
                      >
                        {state["Sélections de déductions Contribuable2"]?.[
                          "Aucunes"
                        ] && (
                            <div className="no-checkbox-mark">
                              <img
                                src="/checkMarkIcon.png"
                                alt="checkmark"
                                className="check-mark-icon"
                              />
                            </div>
                          )}
                      </div>
                      <p className="no">{t("none")}</p>
                    </div>
                    <p className="field-help-p" style={{ fontStyle: "italic" }}>{t("trefundstep5.fields.noneHelp")}</p>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      )}

      {/* <div
        className="personal-information-form"
        style={{ position: "relative" }}
      >
        <ValidationError
          error={Object.keys(personalInformationValidationErrors).length > 0}
          message="All fields required"
          topPosition={"-20px"}
        />
        <h4 style={{ marginBottom: "30px" }} className="checkboxes-title">
          {i18n.language === "fr" ? "Coordonnées" : "Contact details"}
        </h4>

        <div className="regular-input-wrapper">
          <input
            type="text"
            className="activity-input"
            onChange={(e) =>
              handleChangePersonalEnformation("name and lastname", e)
            }
            value={state?.personalInformation?.["name and lastname"] || ""}
          />
          <div className="select-label">{t("trefundstep4.fields.nlname")}</div>
        </div>
        <div className="regular-input-wrapper" style={{ position: "relative" }}>
          <ValidationError
            error={emailError}
            message={emailError}
            topPosition={"-20px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("email", e)}
            value={state?.personalInformation?.email || ""}
          />
          <div className="select-label">Email</div>
        </div>
        <div className="regular-input-wrapper" style={{ position: "relative" }}>
          <ValidationError
            error={phoneError}
            message={phoneError}
            topPosition={"-35px"}
          />
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("mobile", e)}
            value={state?.personalInformation?.mobile || ""}
          />
          <div className="select-label">Mobile</div>
        </div>
        <div className="regular-input-wrapper">
          <input
            type="text"
            className="activity-input"
            onChange={(e) => handleChangePersonalEnformation("address", e)}
            value={state?.personalInformation?.address || ""}
          />
          <div className="select-label">Address</div>
        </div>
      </div> */}
      {state?.price && goToPayment && (
        <Receipt price={state?.price} basepRice={70} step={state?.step} />
      )}
    </div>
  );
}

export default Step5;
