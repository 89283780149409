import React, { useEffect, useRef, useState } from "react";

import "./ourconcept.css";
import { useTranslation } from "react-i18next";

function OurConcept(props) {
  const [t] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pricingTable = useRef(null)
  const [active, setActive] = useState(false);
  let pricingTableBottom
  useEffect(() => {
    pricingTableBottom = pricingTable.current.offsetTop + pricingTable.current.clientHeight / 2;
  }, [pricingTable]);

  window.addEventListener("scroll", () => {
    let currentScrollBottom = window.scrollY + document.documentElement.clientHeight;

    if (currentScrollBottom >= pricingTableBottom) {
      setActive(true)
    }
  })

  const OurConceptContainer = useRef(null)
  useEffect(() => {
    let blobs = OurConceptContainer.current.querySelectorAll(".blob-concept")
    blobs.forEach(blob => {
      blob.classList.add("popInBlob")
      setTimeout(() => {
        blob.classList.remove("popInBlob")
      }, 1000)

      blob.addEventListener('animationend', () => {
        blob.classList.remove('animated');
      });
      blob.addEventListener('mouseover', () => {
        blob.style.animationIterationCount = "infinite"
        blob.classList.add('animated')
      })
      blob.addEventListener('mouseleave', () => {
        blob.style.animationIterationCount = "1"
      })
    })
  }, [OurConceptContainer])

  return (
    <div ref={OurConceptContainer} className="page-margin ourConcept-page">
      <img className="top-corner-blob blob-concept" src="/conceptPage-topRight-blob.svg" alt="conceptPage-topRight-blob.svg"></img>
      <div className="content-padding-left">
        <h4 className="page-title">{t("conceptPage.title")}</h4>
        <p className="ourConcept-description description">{t("conceptPage.ourProduct")}</p>
        <p className="ourConcept-description description">{t("conceptPage.ourProduct2")}</p>
      </div>

      <div className="ourConcept-content-blob-container">
        <div className="ourConcept-content-container">
          <div className="eligibility-container">
            <div className="subtitle-icon-container">
              <img className="eligibility-icon subtitle-icon" src="/profiles-icon.svg" alt="profile-icon.svg"></img>
              <p className="subtitle">{t("conceptPage.eligibility.title")}</p>
            </div>
            <div className="content-padding-left content-subsubtitle-gap">
              <div className="standardProfile-container">
                <p className="description" style={{ marginBottom: "20px" }}>{t("conceptPage.eligibility.standardProfileDescription")}</p>
                <p className="description">{t("conceptPage.eligibility.standardProfileDescription2")}</p>
              </div>
            </div>
          </div>

          <div className="pricing-container">
            <div className="subtitle-icon-container">
              <img className="pricing-icon subtitle-icon" src="/abordable-icon.svg" alt="abordable-icon.svg"></img>
              <p className="subtitle">{t("conceptPage.pricing.title")}</p>
            </div>
            <div className="content-padding-left">
              <div className="pricing-info-container">
                <p className="description">{t("conceptPage.pricing.description")}</p>
                <div className="pricing-table-container">
                  <div ref={pricingTable} className={"pricing-table-border " + (active ? 'active' : undefined)}>
                    <div className="col1 row1">{t("conceptPage.pricing.student")}</div>
                    <div className="col3 row1">50 CHF</div>
                    <div className="line" style={{ gridColumn: "1 / span 3", gridRow: "2" }}></div>
                    <div className="col1 row3">{t("conceptPage.pricing.simple")}</div>
                    <div className="col3 row3">{t("from")} 80 CHF</div>
                    <div className="line" style={{ gridColumn: "1 / span 3", gridRow: "4" }}></div>
                    <div className="col1 row5">{t("conceptPage.pricing.couple")}</div>
                    <div className="col3 row5">{t("from")} 100 CHF</div>
                    <div className="line" style={{ gridColumn: "2", gridRow: "1 / span 5" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accessibility-container">
            <div className="subtitle-icon-container">
              <img className="accessibility-icon subtitle-icon" src="/lightbulb-icon.svg" alt="lightbulb-icon.svg"></img>
              <p className="subtitle">{t("conceptPage.accessibility.title")}</p>
            </div>
            <div className="content-padding-left">
              <p className="description" style={{ marginBottom: "20px" }}>{t("conceptPage.accessibility.description")}</p>
              <p className="description">{t("conceptPage.accessibility.description2")}</p>
            </div>
          </div>
        </div>

        <div className="blob-container">
          <img className="right-dark-blob blob-concept" src="/conceptPage-darkblue-right-blob.svg" alt="conceptPage-darkblue-right-blob.svg"></img>
          <img className="right-light-blob blob-concept" src="/conceptPage-lightblue-right-blob.svg" alt="conceptPage-lightblue-right-blob.svg"></img>
        </div>
      </div>

    </div>
  );
}

export default OurConcept;
