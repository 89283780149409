import React, { useEffect, useRef, useState } from "react";

import "./whoweare.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Button from "../button";

function WhoWeAre() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const vegtaxExperienceBlock = useRef(null)
  const [activeTop, setActiveTop] = useState(false);
  const [activeBottom, setActiveBottom] = useState(false);
  let vegtaxExperienceBlockTop
  let vegtaxExperienceBlockBottom
  useEffect(() => {
    vegtaxExperienceBlockTop = vegtaxExperienceBlock.current.offsetTop;
    vegtaxExperienceBlockBottom = vegtaxExperienceBlock.current.offsetTop + vegtaxExperienceBlock.current.clientHeight;
  }, [vegtaxExperienceBlock]);


  window.addEventListener("scroll", () => {
    let currentScrollBottom = window.scrollY + document.documentElement.clientHeight;

    if (currentScrollBottom >= vegtaxExperienceBlockTop) {
      setActiveTop(true)
    }
    if (currentScrollBottom >= vegtaxExperienceBlockBottom) {
      setActiveBottom(true)
    }
  })

  //Animate floaty blobs when hover
  const aboutUsContainer = useRef(null)
  useEffect(() => {
    let blobs = aboutUsContainer.current.querySelectorAll(".aboutUs-blob")
    blobs.forEach(blob => {
      blob.classList.add("popInBlob")
      setTimeout(() => {
        blob.classList.remove("popInBlob")
      }, 1000)

      blob.addEventListener('animationend', () => {
        blob.classList.remove('animated');
      });
      blob.addEventListener('mouseover', () => {
        blob.style.animationIterationCount = "infinite"
        blob.classList.add('animated')
      })
      blob.addEventListener('mouseleave', () => {
        blob.style.animationIterationCount = "1"
      })
    })
  }, [aboutUsContainer])

  return (
    <div ref={aboutUsContainer} className="aboutUsPage">
      {/* about vegtax and vegconsulting info block (with avatar) */}
      <div className="about-vegtax-vegconsult-block">
        <img className="aboutUs-blob aboutUs-blob-right" src="/blob-about-right.svg" alt="aboutUs-blob-right" />
        <img className="aboutUs-blob aboutUs-blob-left" src="/blob-about-left.svg" alt="aboutUs-blob-left" />

        <div className="about-vegconsult-block about-block">
          <img className="about-vegconsult-logo about-logo" src="/vegconsulting-logo.svg" alt="vegconsulting-logo.svg" />
          <p className="about-vegconsult-p1 about-paragraph">{t("aboutUsPage.vegconsultInfo.vegconsultP1")}</p>
          <p className="about-vegconsult-p2 about-paragraph">{t("aboutUsPage.vegconsultInfo.vegconsultP2")}</p>
          <p className="about-vegconsult-p3 about-paragraph">{t("aboutUsPage.vegconsultInfo.vegconsultP3")}</p>
        </div>

        <img className="about-veg-avatar" src="/avatar-neutral-armscrossed.png" alt="avatar-neutral.png" />
      </div>

      <div ref={vegtaxExperienceBlock} className={"about-vegtax-stat-block " + (activeTop ? 'activeTop ' : undefined) + (activeBottom ? 'activeBottom ' : undefined)}>
        <h4 className="about-vegtax-stat-title">
          {t("aboutUsPage.statInfo.titleStart")}
          <span className="logo-veg medium-logo-span">VEG</span>
          <span className="logo-tax medium-logo-span">TAX.</span>
          {t("aboutUsPage.statInfo.titleEnd")}
        </h4>
        <div className="about-vegtax-stat-content-block">
          <div className="vegtax-stat-block">
            <div className="stat-steps-block stat-block">
              <img className="stat-icon" src="/steps-icon.svg" alt="steps-icon.svg" />
              <div className="steps-text-block stat-text-block">
                <span className="bold">6 </span>
                <span>{t("steps")}</span>
              </div>
            </div>
            <div className="stat-time-block stat-block">
              <img className="stat-icon" src="/time-icon.svg" alt="time-icon.svg" />
              <div className="time-text-block stat-text-block">
                <span className="bold">15 </span>
                <span>{t("minutes")}</span>
              </div>
            </div>
            <div className="stat-money-block stat-block">
              <img className="stat-icon" src="/money-icon.svg" alt="money-icon.svg" />
              <div className="money-text-block stat-text-block">
                <span className="bold"> &lt; 100 </span>
                <span>CHF</span>
              </div>
            </div>
          </div>
          <p className="let-vegtax-guide">{t("aboutUsPage.statInfo.vegtaxGuide")}</p>
          <Button text={t("aboutUsPage.statInfo.letsGo")} onClick={() => navigate("/tax-refund")}></Button>
        </div>
      </div>

      <div className="about-team-block">
        <img className="darkblue-blob ourTeam-blob" src="/ourTeam-darkblue-blob.svg" alt="ourTeam-darkblue-blob.svg" />
        <img className="lightblue-blob ourTeam-blob" src="/ourTeam-lightblue-blob.svg" alt="ourTeam-lightblue-blob.svg" />
        <h4 className="title">{t("aboutUsPage.ourTeam.title")}</h4>
        <div className="team-members-block">
          <div className="team-member-block">
            <div className="member-picture-container">
              <img className="member-picture" src="https://images.pexels.com/photos/2071882/pexels-photo-2071882.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
            </div>
            <div className="member-text">
              <p className="member-name">Nom Prénom</p>
              <p className="member-function">{t("aboutUsPage.ourTeam.function1")}</p>
            </div>
          </div>
          <div className="team-member-block">
            <div className="member-picture-container">
              <img className="member-picture" src="https://images.pexels.com/photos/2071882/pexels-photo-2071882.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
            </div>
            <div className="member-text">
              <p className="member-name">Nom Prénom</p>
              <p className="member-function">{t("aboutUsPage.ourTeam.function2")}</p>
            </div>
          </div>
          <div className="team-member-block">
            <div className="member-picture-container">
              <img className="member-picture" src="https://images.pexels.com/photos/2071882/pexels-photo-2071882.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
            </div>
            <div className="member-text">
              <p className="member-name">Nom Prénom</p>
              <p className="member-function">{t("aboutUsPage.ourTeam.function3")}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default WhoWeAre;
